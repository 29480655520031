import * as React from "react";
import {
  P,
  Flex,
  Touchable,
  H4,
  H5,
  Swiper,
  Chart as UniChart,
  Icon,
} from "unikit";
import dayjs from "dayjs";

import Wrapper from "./Wrapper";
import { useTranslation, useAppState } from "../AppContext";

const getIndex = ({ data }) => {
  return data.findIndex(
    (d) =>
      d.label === dayjs(d.date).format("YYYY") ||
      d.label === dayjs(d.date).format("MMM")
  );
};

const Chart = ({
  data,
  formatValue,
  stock,
  payouts,
  swipeNext,
  showValue = false,
}) => {
  const { formatPrice } = useAppState();
  const [index, setIndex] = React.useState(getIndex({ data }));
  const { t } = useTranslation();

  React.useEffect(() => {
    setIndex(getIndex({ data }));
  }, [data]);

  const chartProps = {
    width: "100%",
    height: showValue ? 80 : 100,
    gap: 3,
    xAxis: true,
    grid: false,
    barColor: "primaryLight",
    selected: index,
    showValue: showValue,
    formatValue,
    barProps: {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    onPress: (bar) => setIndex(bar.index),
    valueOffset: -17,
    valueProps: { color: "text", font: "caption" },
    xAxisProps: { py: 5 },
    rotateValue: false,
  };

  return (
    <Wrapper overflow="visible">
      <Flex
        w="100%"
        bg="surface"
        borderRadius={10}
        shadow={2}
        borderWidth={1}
        borderColor="text"
        borderColorAlpha={0.07}
        overflow="visible"
        h={166}
      >
        <Flex w="100%" p={15} row justifyContent="space-between">
          <Flex justifyContent="space-between" pb={0}>
            <H5 color="primary" font="p">{`${t(
              stock ? "stock.dividend" : "depot.dividend_income"
            )} ${data[index] ? data[index].label : "-"}`}</H5>
            <H4 bold>{`${
              data[index]
                ? formatPrice({ value: data[index].value, suffix: true })
                : 0
            }`}</H4>
          </Flex>
          {payouts ? (
            <Flex>
              <Touchable onPress={() => swipeNext()} flexCenter row>
                <H5 color="primary" font="p">
                  {`${payouts} ${t(`common.payout${payouts > 1 ? "s" : ""}`)}`}
                </H5>
                <Icon name="arrowRight" size={15} />
              </Touchable>
            </Flex>
          ) : null}
        </Flex>
        <Flex absolute w="100%" h={100} l={0} b={showValue ? 29 : 30}>
          <UniChart data={data} {...chartProps} />
        </Flex>
      </Flex>
    </Wrapper>
  );
};

export default function App({
  dividends,
  dividendsByMonth,
  chart,
  stock = false,
  price,
  cap,
  payouts,
}) {
  const swiperRef = React.useRef();
  const { formatPrice } = useAppState();
  const { t } = useTranslation();
  const value = chart[chart.length - 1] ? chart[chart.length - 1].value : 0;
  const divi = dividends[dividends.length - 1]
    ? dividends[dividends.length - 1].value
    : 0;
  const diviYield = ((divi / value) * 100).toFixed(2);

  const swipeNext = () => {
    if (swiperRef.current) swiperRef.current.swipeNext();
  };

  return (
    <>
      <Flex bg="primary" w="100%" pt={20} pb={120}>
        <Wrapper px={20} justifyContent="space-between" row>
          <Flex>
            <P opacity={0.7} font="p">
              {price ? t("stock.price") : t("depot.capital_invested")}
            </P>
            <H4 bold color="#FFF">
              {price ||
                `${formatPrice({
                  value,
                  suffix: true,
                })}`}
            </H4>
          </Flex>
          <Flex>
            <P opacity={0.7} font="p">
              {cap ? t("stock.cap") : t("depot.global_yield")}
            </P>
            <H4 bold color="#FFF" textAlign="right">
              {cap || `${diviYield && diviYield !== "NaN" ? diviYield : 0}%`}
            </H4>
          </Flex>
        </Wrapper>
      </Flex>

      <Flex w="100%" mt={-100}>
        <Swiper
          ref={swiperRef}
          height={202}
          dotsProps={{
            position: "bottom",
            trackColor: "rgba(0,0,0,0.5)",
            roundness: 0,
            trackSize: 1,
            itemSize: 30,
            offset: 10,
          }}
        >
          <Chart
            data={dividends}
            stock={stock}
            payouts={payouts}
            swipeNext={swipeNext}
          />
          <Chart
            data={dividendsByMonth}
            stock={stock}
            showValue
            formatValue={(v) =>
              formatPrice({
                value: v > 100 ? parseInt(v.toFixed(0)) : v,
                suffix: true,
              })
            }
          />
        </Swiper>
      </Flex>
    </>
  );
}
