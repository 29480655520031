import React from "react";
import {
  Animate,
  Flex,
  useTheme,
  H4,
  P,
  Icon,
  Button,
  Chart,
  Progress,
} from "unikit";

import { useTranslation } from "../AppContext";
import { cleanName } from "../stocklytics/lib/helper";

function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}

export default ({
  title,
  chart,
  desc,
  action,
  watchlist,
  full = false,
  symbol = "",
  rows,
  score,
  ...rest
}) => {
  const theme = useTheme();
  const isDark = theme.mode === "dark";
  const { t } = useTranslation();
  return (
    <Animate from={{ o: 0, x: 100 }} to={{ o: 1, y: 0 }}>
      <Flex
        w={full ? "100%" : 300}
        bg="surface"
        borderTopWidth={1}
        borderRightWidth={1}
        borderLeftWidth={1}
        borderColor="text"
        borderColorAlpha={isDark ? 0.03 : 0.07}
        shadow={2}
        borderRadius={15}
        relative
        {...rest}
      >
        {score && (
          <Flex
            absolute
            t={15}
            r={15}
            style={{ transform: [{ translateY: 5 }] }}
          >
            <Progress
              trackSize={2}
              angle={220}
              size={40}
              max={39}
              trackColor="rgba(255,255,255,0.1)"
              progressColor={
                score < 18 ? "error" : score < 28 ? "warning" : "primary"
              }
              indicatorColor="#FFF"
              value={score}
              showValue={true}
            />
          </Flex>
        )}
        <Flex
          maxWidth="75%"
          px={25}
          pt={20}
          minHeight={theme.isMobile ? 75 : 110}
        >
          <P font="label" color="primary" uppercase>
            {symbol.split(".")[0]}
          </P>
          <H4 fontWeight="bold" mt={3} pr={action ? 40 : 0} numberOfLines={1}>
            {cleanName(title)}
          </H4>
          {desc ? (
            <P mt={5} opacity={0.5} font="label">
              {desc}
            </P>
          ) : null}
        </Flex>
        {rows ? (
          <Flex w="100%" pb={15} mt={-5} px={25} row content="space-between">
            {rows.map((row, index) => {
              return (
                <React.Fragment key={index}>
                  <Flex>
                    <P font="label" color="primary" uppercase>
                      {row.label}
                    </P>
                    <Flex ai="center" row>
                      {row.value && isFunction(row.value) ? (
                        row.value()
                      ) : (
                        <P fontSize="label" mt={1} mb={6}>
                          {row.value || ""}{" "}
                        </P>
                      )}

                      {row.pills
                        ? row.pills.map((pill) => {
                            return (
                              <Button
                                size={18}
                                ml={row.value ? 5 : 0}
                                light
                                rounded
                                bg={pill.type}
                                labelProps={{ style: { fontSize: 10 } }}
                              >
                                {pill.value}
                              </Button>
                            );
                          })
                        : null}
                    </Flex>
                  </Flex>
                </React.Fragment>
              );
            })}
          </Flex>
        ) : null}
        {chart && (
          <Flex w="40%" h={50} absolute b={30} r={0}>
            <Chart
              data={chart}
              xAxis={false}
              grid={false}
              gap={3}
              showValue={false}
              barColor={"primaryLight"}
              width="100%"
              height={50}
              scrollable={false}
              minBarWidth={0}
            />
          </Flex>
        )}
      </Flex>

      {action ? (
        <Button
          onPress={(e) => {
            action();
          }}
          light
          rounded
          size={30}
          absolute
          r={15}
          t={15}
        >
          <Icon name="edit" size={15} />
        </Button>
      ) : null}
    </Animate>
  );
};
