import * as React from "react";
import { FlatList, Platform } from "react-native";
import dayjs from "dayjs";
import {
  styled,
  Button,
  Icon,
  P,
  isWeb,
  H4,
  H5,
  Flex,
  Grid,
  Progress,
  getProgress,
  Group,
  Dropdown,
  Input,
} from "unikit";
import { useSafeArea } from "react-native-safe-area-context";

import { Wrapper, NoContent, Page } from "../components";
import { useAppState, useTranslation } from "../AppContext";
import { cleanName } from "../stocklytics/lib/helper";

const Spacer = styled.View();

const VIEWABILITY_CONFIG = {
  minimumViewTime: 3000,
  viewAreaCoveragePercentThreshold: 100,
  waitForInteraction: true,
};

const MONTHS = () => {
  const months = [];
  const firstDay = dayjs().startOf("year");
  Array.from(Array(12).keys()).map((index) => {
    months.push(dayjs(firstDay).add(index, "month").toDate());
  });
  return months;
};

const getDays = (date) => {
  return Array.from(
    Array(parseInt(dayjs(date).daysInMonth())).keys()
  ).map((day) => dayjs(date).startOf("month").add(day, "days").toDate());
};

const MonthList = ({
  month,
  eventsByMonth = [],
  removeEmpty = false,
  setDate,
  showHeader = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const scrollRef = React.useRef(null);
  const insets = useSafeArea();
  const { formatPrice } = useAppState();

  const days = React.useMemo(() => {
    const daysWithEvents = [];
    const daysByMonth = getDays(month);
    daysByMonth.map((day) => {
      const isSame = false; //dayjs().isSame(day, "day") && dayjs().isSame(day, "month");
      const eventsByDay = eventsByMonth.filter((e) =>
        dayjs(day).isSame(e.date, "day")
      );
      if (
        (eventsByDay.length === 0 && !removeEmpty) ||
        (removeEmpty && eventsByDay.length > 0) ||
        isSame
      ) {
        daysWithEvents.push({
          day: day,
          events: eventsByDay,
        });
      }
    });
    return daysWithEvents;
  }, [eventsByMonth, month]);

  const todayIndex = days.findIndex(
    (d) => dayjs().isSame(d.day, "day") && dayjs().isSame(d.day, "month")
  );

  const scrollTo = ({ index }) => {
    if (scrollRef.current && days.length > 0) {
      scrollRef.current.scrollToIndex({
        animated: true,
        index: index > -1 ? index : 0,
      });
    }
  };

  React.useEffect(() => {
    if (!isWeb) {
      scrollRef.current.scrollResponderHandleStartShouldSetResponder = () =>
        true;
      setTimeout(() => {
        scrollTo({ index: todayIndex > -1 ? todayIndex : 0 });
      }, 10);
    }
  }, [month]);

  const recieved = React.useMemo(() => {
    return eventsByMonth.reduce((acc, event) => {
      if (
        event.type === "dividend_payout" &&
        dayjs(event.date).isBefore(dayjs())
      ) {
        acc = acc + event.value;
      }
      return acc;
    }, 0);
  }, [eventsByMonth]);

  const expected = React.useMemo(() => {
    return eventsByMonth.reduce((acc, event) => {
      if (event.type === "dividend_payout") {
        acc = acc + event.value;
      }
      return acc;
    }, 0);
  }, [eventsByMonth]);

  const progress = getProgress(0, expected, recieved) * 100;
  const isCurrentMonth = dayjs(month).isSame(dayjs(), "month");

  return (
    <>
      <FlatList
        style={{
          height: "100%",
        }}
        ListHeaderComponent={
          <>
            <Spacer
              w="100%"
              mt={isWeb ? 0 : -200}
              h={isWeb ? 150 : 250 + insets.top}
            />
            {showHeader && (
              <Flex
                bg="surface"
                borderTopWidth={1}
                borderRightWidth={1}
                borderLeftWidth={1}
                borderColor="text"
                borderColorAlpha={0.05}
                shadow={2}
                borderRadius={15}
                mb={10}
                borderRadius={7}
                p={10}
              >
                <Wrapper>
                  <Flex row justifyContent="space-between" alignItems="center">
                    <Flex style={{ transform: [{ translateY: 5 }] }}>
                      <Progress
                        trackSize={3}
                        angle={220}
                        size={60}
                        trackColor="rgba(255,255,255,0.1)"
                        indicatorColor="#FFF"
                        value={progress > -1 ? progress : 0}
                        showValue={true}
                        formatValue={(v) => `${v.toFixed(0)}%`}
                      />
                    </Flex>
                    <Flex>
                      <P opacity={0.7} font="p">
                        {t("calendar.recieved")}
                      </P>
                      <H4 font="h5" bold>
                        {formatPrice({ value: recieved, suffix: true })}
                      </H4>
                    </Flex>
                    <Flex>
                      <P opacity={0.7} font="p" textAlign="right">
                        {t("calendar.expected")}
                      </P>
                      <H4 font="h5" bold textAlign="right">
                        {formatPrice({ value: expected, suffix: true })}
                      </H4>
                    </Flex>
                  </Flex>
                </Wrapper>
              </Flex>
            )}
          </>
        }
        data={days}
        scrollEventThrottle={10}
        getItemLayout={(data, i) => ({
          length: 200,
          offset:
            data[i].events.length > 0 ? data[i].events.length * 100 : 60 * i,
          index: i,
        })}
        ListEmptyComponent={
          <NoContent
            title={t("events.empty_title")}
            action={isCurrentMonth ? undefined : () => setDate(new Date())}
            actionTitle={isCurrentMonth ? undefined : t("events.show_current")}
          />
        }
        keyExtractor={(item, index) => `list-item-${index}`}
        renderItem={({ item, index }) => {
          const isSame =
            dayjs().isSame(item.day, "day") &&
            dayjs().isSame(item.day, "month");
          return (
            <Flex w="100%" row h="auto" my={5} minHeight={60}>
              <Flex w={30} h={50} mt={8} mr={10} flexCenter>
                <H4 color={isSame ? "primary" : "text"} bold={isSame === true}>
                  {dayjs(item.day).format("DD")}
                </H4>
                <P color="primary" mt={0}>
                  {dayjs(item.day).format("ddd")}
                </P>
              </Flex>
              <Flex
                borderTopWidth={1}
                borderColor="text"
                borderColorAlpha={0.06}
                flex={1}
                pt={5}
              >
                <Grid min={300} gap={10}>
                  {item.events.map((event) => {
                    return (
                      <Flex
                        w="100%"
                        bg="surface"
                        borderTopWidth={1}
                        borderRightWidth={1}
                        borderLeftWidth={1}
                        borderColor="text"
                        borderColorAlpha={0.05}
                        shadow={2}
                        borderRadius={15}
                        p={20}
                      >
                        <Flex row justifyContent="space-between">
                          <Flex maxWidth="70%">
                            <H5>{cleanName(event.title)}</H5>
                            <P color="primary">{`${t(
                              `events.${event.type}`
                            )}`}</P>
                          </Flex>

                          {event.value ? (
                            <Flex>
                              <H5 textAlign="right" bold>
                                {formatPrice({
                                  value: event.value,
                                  suffix: true,
                                })}
                              </H5>
                              {event.growth && event.growth !== 0 ? (
                                <P
                                  color={event.growth > 0 ? "primary" : "error"}
                                  textAlign="right"
                                >{`${event.growth > 0 ? "+" : ""}${(
                                  event.growth * 100
                                ).toFixed(2)}%`}</P>
                              ) : null}
                            </Flex>
                          ) : null}
                        </Flex>
                      </Flex>
                    );
                  })}
                </Grid>
              </Flex>
            </Flex>
          );
        }}
        viewabilityConfig={VIEWABILITY_CONFIG}
        {...rest}
        ref={scrollRef}
      />
    </>
  );
};

export default function HomeScreen() {
  const { user, userLoading } = useAppState();
  const { t } = useTranslation();
  const [filter, setFilter] = React.useState({
    dividend_ex: true,
    dividend_payout: true,
    earnings: false,
  });
  const [date, setDate] = React.useState(new Date());

  const { depots = [] } = user || {};

  const eventsByMonth = React.useMemo(() => {
    return depots.length === 0
      ? []
      : depots
          .filter((d) => d.watchlist !== true)
          .reduce((acc, depot) => {
            const events =
              depot.events && depot.events
                ? depot.events.filter(
                    (e) =>
                      dayjs(date).isSame(e.date, "month") &&
                      filter[e.type] === true
                  )
                : [];
            events.map((event) => {
              const found = acc.find(
                (e) => e.title === event.title && e.date === event.date
              );
              if (!found) {
                acc.push(event);
              }
            });
            return acc;
          }, []);
  }, [depots, date, filter]);

  console.log({ eventsByMonth });

  if (!user || userLoading) {
    return <Page loading authAction={!userLoading} />;
  }

  return (
    <Page
      desc={`${dayjs(date).format("YYYY")}`}
      title={dayjs(date).format("MMMM")}
      noBg
      leftAction={
        <Group w={70}>
          <Button
            onPress={() => setDate(dayjs(date).subtract(1, "month").toDate())}
            size={30}
            rounded
            bg="surface"
          >
            <Icon name="arrowLeft" size={20} />
          </Button>
          <Button
            onPress={() => setDate(dayjs(date).add(1, "month").toDate())}
            size={30}
            rounded
            bg="surface"
          >
            <Icon name="arrowRight" size={20} />
          </Button>
        </Group>
      }
      rightAction={
        <Dropdown
          backdrop
          wrapperProps={{
            w: 275,
            r: 0,
            shadow: 60,
            t: "140%",
          }}
          content={({ close }) => (
            <Flex row w="100%">
              <Input
                type="multiswitch"
                value={filter}
                options={[
                  {
                    label: t(`events.dividend_ex`),
                    value: "dividend_ex",
                  },
                  {
                    label: t(`events.dividend_payout`),
                    value: "dividend_payout",
                  },
                  {
                    label: t(`events.earnings`),
                    value: "earnings",
                  },
                ]}
                onChange={(v) => setFilter(v)}
              />
            </Flex>
          )}
        >
          <Button
            onPress={() => setDate(dayjs(date).add(1, "month").toDate())}
            size={30}
            rounded
          >
            <Icon name="moreHorizontal" size={20} color="#FFF" />
          </Button>
        </Dropdown>
      }
      scrollable={isWeb}
      noSpacer
    >
      {(scrollProps) => (
        <Wrapper mt={30}>
          <MonthList
            month={date}
            eventsByMonth={eventsByMonth}
            removeEmpty
            setDate={setDate}
            showHeader={filter.dividend_payout === true}
            {...scrollProps}
          />
        </Wrapper>
      )}
    </Page>
  );
}
