import * as React from "react";
import * as Localization from "expo-localization";
import i18n from "i18n-js";

i18n.translations = {
  en: {
    common: {
      my_depots: "My Portfolios",
      depot: "Portfolio",
      depots: "Portfolios",
      watchlists: "Watchlists",
      next: "Next",
      login: "Login",
      register: "Register",
      years: "Years",
      payout: "Payout",
      payouts: "Payouts",
      cancel: "Cancel",
      yes: "Yes",
      sure_question: "Are you sure?",
      empty: "No results",
      price: "Price",
      imprint: "Imprint",
      privacy: "Privacy Policy",
      terms: "Terms of Use",
      support: "Feedback\nRequest missing Symbol",
    },
    user: {
      empty: "No Portfolio existing",
      empty_desc: "Creat your first portfolio.",
      password: "Password",
      password_placeholder: "Your password",
      password_repeat: "Repeat Password",
      password_repeat_placeholder: "Your password",
    },
    depot: {
      my_depots: "My Portfolios",
      create: "New Portfolio",
      holdings: "Holdings",
      capital_invested: "Captial invested",
      global_yield: "Overall yield",
      dividend_income: "Dividend income",
      empty: "No Holdings",
      empty_desc: "Start by adding some Stocks",
    },
    stock: {
      dividend: "Dividend",
      price: "Price",
      cap: "Market Cap",
      select_depot: "Select Depot",
      dividends: "Dividends",
      amount: "Amount",
      yield: "Yield",
      inHoldings: "Already added",
      totalRevenue: "Revenue",
      netIncome: "Net Income",
      totalCashFromOperatingActivities: "Cashflow",
      cash: "Cash",
      dividendsPaid: "Dividends Paid",
      shortTermDebt: "Short term debt",
      longTermDebtTotal: "Debt",
    },
    pro: {
      yearly: "Yearly",
      monthly: "Monthly",
      action: "Start for free",
    },
    settings: {
      title: "Settings",
    },
    search: {
      placeholder: "Search for Company or Ticker",
      score: "Divilytics Score",
      results: "Results",
      filter: "Filter",
      sector_placeholder: "No Sector",
      apply: "Apply",
      empty_desc: "Your ticker symbol is missing?",
      request_symbol: "Request symbol",
      score_info:
        "Investing in leading growth stocks can produce outstanding returns over the long term. We look into important key metrics for dividend growth stocks and send you a monthly rebalacing alert for your strategy.",
    },
    events: {
      earnings: "Quarterly Report",
      payment: "Dividend Payout",
      record: "Ex-Dividend Day",
      dividend_payout: "Dividend Payout",
      dividend_ex: "Ex-Dividend Day",
      empty_title: "No Events Found",
      show_current: "Show Current Month",
    },
    calendar: {
      recieved: "Recieved",
      expected: "Expected",
    },
    form: {
      light: "Light",
      dark: "Dark",
      darkTheme: "Appearance",
      emails: "E-Mail",
      currency: "Currency",
      notifications: "Notifications",
      pro_notifications: "Pro Notifications",
      rebalance: "Rebalancing of Pro Strategies",
      earnings: "Quarterly Reports",
      dividend_payout: "Dividend Payout",
      dividend_ex: "Ex-Dividend Day",
      dividend_change: "Dividend change",
      growth: "Growth",
      dividends: "Dividends",
      preference: "Preference",
      public: "Public",
      name: "Name",
      holdings: "Holdings",
      watchlist: "Watchlist",
      score: "Divilytics Score",
      rebalancing: "Monthly Rebalancing",
    },
    SplitsDividends: {
      ForwardAnnualDividendYield: "Dividend Yield",
      ForwardAnnualDividendRate: "Dividend Rate",
      DividendDate: "Payout-Date",
      ExDividendDate: "Ex-Dividend Date",
      GrowthTTM: "Dividend Growth TTM",
      Growth3Y: "Dividend Growth 3Y",
      GrowthYears: "Dividend Growth years",
      PayoutRatio: "Payout Ratio",
      PDGRatio: "Price/Dividends Growth Ratio",
      count: "Payouts",
    },
    Financials: {
      RuleOf40: "RuleOf40",
      GrossMargin: "Gross Margin",
      PEGRatio: "Price/Earnings Growth Ratio",
    },
  },
  de: {
    common: {
      my_depots: "Meine Depots",
      depot: "Depot",
      depots: "Depots",
      watchlists: "Watchlisten",
      next: "Weiter",
      login: "Login",
      register: "Registrieren",
      years: "Jahre",
      payout: "Payout",
      payouts: "Payouts",
      cancel: "Abbrechen",
      yes: "Ja",
      sure_question: "Bist du sicher?",
      empty: "Keine Ergebnisse",
      price: "Kurs",
      imprint: "Impressum",
      privacy: "Privacy",
      terms: "Terms of Use",
      support: "Feedback\nAktie fehlt",
    },
    user: {
      empty: "Kein Depot vorhanden",
      empty_desc: "Erstelle jetzt dein erstes Depot",
      password: "Passwort",
      password_placeholder: "Dein Passwort",
      password_repeat: "Passwort widerholen",
      password_repeat_placeholder: "Dein Passwort",
    },
    depot: {
      my_depots: "Meine Depots",
      create: "Neues Depot",
      holdings: "Holdings",
      capital_invested: "Investiertes Kapital",
      global_yield: "Dividendenrendite",
      dividend_income: "Dividenden Einnahmen",
      empty: "Keine Holdings",
      empty_desc: "Füge jetzt deine ersten Aktien hinzu",
    },
    stock: {
      dividend: "Dividende",
      price: "Kurs",
      cap: "Market Cap",
      select_depot: "Depot wählen",
      dividends: "Dividenden",
      amount: "Anzahl",
      yield: "Dividendenrendite",
      inHoldings: "Schon im Depot vorhanden",
      totalRevenue: "Umsatz",
      netIncome: "Gewinn",
      totalCashFromOperatingActivities: "Cashflow",
      cash: "Cash",
      dividendsPaid: "Dividenden",
      shortTermDebt: "Kurzfristige Schulden",
      longTermDebtTotal: "Schulden",
    },
    pro: {
      yearly: "Yearly",
      monthly: "Monthly",
      action: "Starte 7 Tage kostenlos",
    },
    settings: {
      title: "Einstellungen",
    },
    search: {
      placeholder: "Suche nach Firmenname oder Symbol",
      score: "Divilytics Score",
      results: "Ergebnisse",
      filter: "Filter",
      sector_placeholder: "Kein Sektor",
      apply: "Anwenden",
      empty_desc: "Deine Aktie/ETF nicht gefunden?",
      request_symbol: "Zum Support",
      score_info:
        "Investitionen in führende Wachstumswerte können langfristig hervorragende Renditen erzielen. Wir untersuchen wichtige Schlüsselkennzahlen für Dividenden-Wachstumstitel und senden dir monatlich einen 'Rebalacing Alert' für deine Strategie.",
    },
    events: {
      earnings: "Quartalszahlen",
      payment: "Dividendenzahlung",
      record: "Dividendenstichtag",
      dividend_payout: "Dividenden Zahlung",
      dividend_ex: "Dividenden Stichtag",
      empty_title: "Keine Events gefunden",
      show_current: "Zum aktuellen Monat",
    },
    calendar: {
      recieved: "Erhalten",
      expected: "Erwartet",
    },
    form: {
      light: "Hell",
      dark: "Dunkel",
      darkTheme: "Aussehen",
      emails: "E-Mail",
      currency: "Währung",
      notifications: "Benachrichtigungen",
      pro_notifications: "Pro Benachrichtigungen",
      rebalance: "Rebalancing von Pro Strategien",
      earnings: "Quartalszahlen",
      dividend_payout: "Dividenden Zahlung",
      dividend_ex: "Dividenden Stichtag",
      growth: "Wachstum",
      dividends: "Dividenden",
      preference: "Präferenz",
      public: "Öffentlich",
      name: "Name",
      holdings: "Holdings",
      watchlist: "Als Watchlist nutzen",
      score: "Divilytics Score",
      rebalancing: "Montl. Rebalancing",
    },
    SplitsDividends: {
      ForwardAnnualDividendYield: "Dividendenrendite",
      ForwardAnnualDividendRate: "Dividendensatz",
      DividendDate: "Auszahlungs-Datum",
      ExDividendDate: "Ex-Datum",
      GrowthTTM: "Dividenden-Wachstum TTM",
      Growth3Y: "Dividenden-Wachstum 3Y",
      GrowthYears: "Dividende jährlich gewachsen",
      PayoutRatio: "Payout Ratio",
      PDGRatio: "Price/Dividends Growth Ratio",
      count: "Auszahlungen",
    },
    Financials: {
      RuleOf40: "RuleOf40",
      GrossMargin: "Gross Margin",
      PEGRatio: "Price/Earnings Growth Ratio",
    },
  },
};

// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;
// When a value is missing from a language it'll fallback to another language with the key present.
i18n.fallbacks = true;

export const useTranslation = () => {
  const t = (s) => {
    return i18n.t(s);
  };
  return { t };
};
