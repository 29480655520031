import * as React from "react";
import { H3, P, BR } from "unikit";
// import ReactHtmlParser from "react-html-parser";

import { Page, Wrapper } from "../components";
import { useTranslation } from "../AppContext";

export default function Screen({ route: { params }, navigation: { goBack } }) {
  const { mode } = params;
  const { t, locale } = useTranslation();
  return (
    <Page title={t(`common.${mode}`)} goBack={goBack} noBg navProps={{ p: 0 }}>
      {mode === "imprint" && (
        <Wrapper mt={20}>
          <H3 mt={50} bold>
            Kontakt
          </H3>
          <P mt={10}>
            Divilytics{"\n"}
            Thomas Schöller{"\n"}
            Lötsch 14a{"\n"}
            41334 Nettetal{"\n"}
            <BR />
            Steuer-Nummer: DE 115 / 5174 / 1695{"\n"}
            USt-IdNr. DE297694813
            <BR />
            E-Mail: info@Divilytics.io
          </P>

          <H3 mt={50} bold>
            Keine Anlageberatung
          </H3>
          <P mt={10}>
            Die hier angebotenen Inhalte dienen ausschließlich zu
            Anschauungszwecken und sind keinesfalls als Handlungsaufforderung
            zum Kauf, Halten oder Verkauf von Wertpapieren zu verstehen. Unsere
            Inhalte ersetzen in keiner Weise eine fachkundige Anlageberatung.
            Personen, die aufgrund der hier zur Verfügung gestellten
            Informationen Anlageentscheidungen treffen, handeln vollständig auf
            eigene Gefahr.
          </P>

          <H3 mt={50} bold>
            Haftungsausschluss
          </H3>
          <P mt={10}>
            Empfehlungen sind keine Aufforderungen zum Kauf oder Verkauf von
            Wertpapieren. Der Autor übernimmt keinerlei Gewähr hinsichtlich der
            inhaltlichen Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit
            und Vollständigkeit der Informationen.
            <BR />
            Haftungsansprüche gegen den Autor wegen Schäden materieller oder
            immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw.
            Nichtnutzung der veröffentlichten Informationen, durch Missbrauch
            der Verbindung oder durch technische Störungen entstanden sind,
            werden ausgeschlossen.
            <BR />
            Alle Angebote sind unverbindlich. Der Autor behält es sich
            ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne
            gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder
            die Veröffentlichung zeitweise oder endgültig einzustellen. Weiter
            möchten der Autor darauf hinweisen, dass eine Investition in
            Wertpapiere mit geringer Liquidität sowie niedriger
            Börsenkapitalisierung höchst spekulativ ist und ein sehr hohes
            Risiko darstellt. Aufgrund des spekulativen Charakters der
            dargestellten Unternehmen, ist es durchaus möglich, dass bei
            Investitionen Kapitalminderungen, bis hin zum Totalverlust eintreten
            können. Jedes Investment in Aktien ist mit Risiken verbunden. Eine
            Anlageentscheidung hinsichtlich irgendeines Wertpapiers darf nicht
            auf der Grundlage von Divilytics bzw. allein deren Daten erfolgen.
            Der Herausgeber von Divilytics ist nicht verantwortlich für
            Konsequenzen, speziell für Verluste, welche durch die Verwendung,
            oder die Unterlassung der Verwendung aus den in den
            Veröffentlichungen enthaltenen Ansichten und Rückschlüsse folgen
            bzw. folgen könnten, und übernimmt keine Garantie dafür, dass der
            erwartete Gewinn oder die genannten Kursziele erreicht werden. Bevor
            Sie irgendwelche Investments tätigen, ist eine professionelle
            Beratung durch ihre Bank unumgänglich.
          </P>

          <H3 mt={50} bold>
            Haftung für Links
          </H3>
          <P mt={10}>
            Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres
            Verantwortungsbereichs Es wird jegliche Verantwortung für solche
            Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten
            erfolgen auf eigene Gefahr des Nutzers oder der Nutzerin.
          </P>

          <H3 mt={50} bold>
            Urheberrechte
          </H3>
          <P mt={10}>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
            Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
            Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
            gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
            werden wir derartige Inhalte umgehend entfernen.
          </P>

          <H3 mt={50} bold>
            Datenschutz
          </H3>
          <P mt={10}>
            Die Nutzung unserer Webseite ist in der Regel ohne Angabe
            personenbezogener Daten möglich. Soweit auf unseren Seiten
            personenbezogene Daten (beispielsweise Name, Anschrift oder
            E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
            auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
            Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin,
            dass die Datenübertragung im Internet (z.B. bei der Kommunikation
            per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz
            der Daten vor dem Zugriff durch Dritte ist nicht möglich. Der
            Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
            angeforderter Werbung und Informationsmaterialien wird hiermit
            ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
            ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
            von Werbeinformationen, etwa durch Spam-Mails, vor.
          </P>

          <H3 mt={50} bold>
            Datenschutzerklärung für die Nutzung von Google Analytics
          </H3>
          <P mt={10}>
            Diese Website benutzt Google Analytics, einen Webanalysedienst der
            Google Inc. (Google). Google Analytics verwendet sog. Cookies,
            Textdateien, die auf Ihrem Computer gespeichert werden und die eine
            Analyse der Benutzung der Website durch Sie ermöglichen. Die durch
            den Cookie erzeugten Informationen über Ihre Benutzung dieser
            Website werden in der Regel an einen Server von Google in den USA
            übertragen und dort gespeichert. Im Falle der Aktivierung der
            IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse von
            Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union
            oder in anderen Vertragsstaaten des Abkommens über den Europäischen
            Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle
            IP-Adresse an einen Server von Google in den USA übertragen und dort
            gekürzt. Google wird diese Informationen benutzen, um Ihre Nutzung
            der Website auszuwerten, um Reports über die Websiteaktivitäten für
            die Websitebetreiber zusammenzustellen und um weitere mit der
            Websitenutzung und der Internetnutzung verbundene Dienstleistungen
            zu erbringen. Auch wird Google diese Informationen gegebenenfalls an
            Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit
            Dritte diese Daten im Auftrag von Google verarbeiten. Die im Rahmen
            von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
            nicht mit anderen Daten von Google zusammengeführt. Sie können die
            Installation der Cookies durch eine entsprechende Einstellung Ihrer
            Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass
            Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser
            Website voll umfänglich nutzen können. Durch die Nutzung dieser
            Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen
            Daten durch Google in der zuvor beschriebenen Art und Weise und zu
            dem zuvor benannten Zweck einverstanden.
          </P>
        </Wrapper>
      )}

      {mode === "privacy" && (
        <Wrapper mt={20}>
          <P mt={50}>
            Diese Datenschutzerklärung gilt für das Produkt Divilytics. Die
            Divilytics, Thomas Schöller, Lötsch 14a, 41334 Nettetal (nachfolgend
            „Divilytics " oder „wir/uns") betreibt unter der Domain
            „Divilytics.io" und mithilfe der für verschiedene Betriebssysteme
            zum Download bereitstehenden mobilen App Divilytics (nachfolgend:
            „Divilytics-App“) eine Plattform zum verwalten und analysieren von
            Aktien. Deine Daten werden nach den Bestimmungen des
            Telemediengesetzes (TMG) und des Datenschutzrechts, insbesondere des
            Bundesdatenschutzgesetzes (BDSG) – ab dem 25. Mai 2018 der
            Datenschutz-Grundverordnung (DS-GVO) – erhoben, verarbeitet und
            genutzt. Diese Datenschutzerklärung erläutert den Umgang mit
            personenbezogenen Daten. Personenbezogene Daten sind Einzelangaben
            über persönliche oder sachliche Verhältnisse einer bestimmten oder
            bestimmbaren natürlichen Person. Dazu gehören z.B. Name, Geburtstag,
            Telefonnummer aber auch E-Mail-Adresse und Nutzungsdaten, wie zum
            Beispiel die IP-Adresse. Weiterhin dient die Datenschutzerklärung
            der Information darüber, welche Daten erhoben, gespeichert und
            verarbeitet werden und wie die Minnie Partners UG den Schutz und die
            Sicherheit der personenbezogenen Daten gewährleistet. Divilytics
            verzichtet auf einen Klarnamenzwang. Der Nutzer kann anstelle eines
            Namens auch ein Pseudonym angeben.
          </P>

          <H3 mt={50} bold>
            a. Wie werden meine Daten beim Download der Divilytics-App erhoben,
            verarbeitet und genutzt?
          </H3>
          <P mt={10}>
            a.1. Download der Divilytics-App Beim Herunterladen der
            Divilytics-App werden die dafür notwendigen Informationen an den
            jeweiligen App-Store-Betreiber übertragen. Dies sind je nach
            App-Store beispielsweise die E-Mail-Adresse oder die Kundennummer
            des App-Store-Benutzers des jeweiligen App-Stores, der Zeitpunkt des
            Downloads und eine individuelle Gerätekennziffer. Auf diese
            Datenerhebung hat die Divilytics jedoch keinen Einfluss und ist
            nicht dafür verantwortlich. Es gelten die entsprechenden
            Datenschutzerklärungen/ Einstellungen des jeweiligen App-Store
            Betreibers. Die Divilytics verarbeitet die vom jeweiligen
            App-Store-Betreiber bereitgestellten Daten, soweit dies für das
            Herunterladen der Divilytics-App auf das Endgerät notwendig ist.
            a.2. Installation und Berechtigungen Zur technischen
            Funktionsfähigkeit der Divilytics-App und zur Bereitstellung der mit
            der Divilytics-App angebotenen Dienste benötigt Divilytics
            verschiedene Zugriffsmöglichkeiten und Informationen. Je nach
            Betriebssystem wird bereits beim Installationsvorgang eine Erlaubnis
            für den Zugriff auf einzelne Funktionen und Informationen abgefragt.
            Die Zugriffsberechtigungen umfassen unter anderem den Standort, die
            Benachrichtigungen sowie die mobilen Daten. Unter den Einstellungen
            des Endgeräts können diese Berechtigungen zum Teil manuell entzogen
            werden. Dabei ist jedoch zu berücksichtigen, dass die Divilytics-App
            ohne entsprechende Freigaben nur eingeschränkt bzw. gar nicht
            genutzt werden kann. Je nach App-Version werden vor oder nach der
            Installation Berechtigungen abgefragt.
          </P>

          <H3 mt={50} bold>
            b. Wie werden meine Daten bei Besuch der Divilytics-Domain bzw. der
            Divilytics-App ohne Erstellen eines Nutzerprofils bei Divilytics
            erhoben, verarbeitet und genutzt?
          </H3>
          <P>
            Die Divilytics verwendet die personenbezogenen Daten, die der Nutzer
            mitteilt oder die bei der Nutzung von Divilytics ohne Erstellen
            eines Nutzerprofils anfallen, ohne gesonderte Einwilligung
            ausschließlich zur Durchführung des Nutzungsverhältnisses und wie
            entsprechend in dieser Datenschutzerklärung dargestellt. Beim bloßen
            Besuch der Divilytics-Domain (auf einer sogenannten
            Landing-Page/Startseite) bzw. Installation der Divilytics-App ohne
            Erstellung eines Nutzerprofils, erhebt die Divilytics die folgenden
            Daten, die das Endgerät bzw. der Browser des Nutzers übermittelt:
            IP-Adresse Name der aufgerufenen Webseite, Datei, Datum und Uhrzeit
            der Anfrage übertragene Datenmenge Browsertyp und -version
            Referrer-URL (Herkunfts-URL), von der der Nutzer auf die abgerufene
            Seite gekommen ist Sowie zusätzlich bei der bloßen Installation der
            Divilytics-App ohne Registrierung oder Erstellung eines
            Nutzerprofils folgende Daten, die das verwendete Endgerät des
            Nutzers übermittelt: Push Handle (zum Verschicken von Push
            Nachrichten) Standortdaten (GPS Location) Ländercode Sprache
            Gerätename Name des Betriebssystems und –version Diese Daten sind
            für die Nutzung der Divilytics-Domain bzw. Installation der
            Divilytics-App als solche erforderlich bzw. dienen der Auswertung zu
            statistischen Zwecken zur Optimierung von Divilytics. Die Daten
            werden zur Prävention und Bekämpfung von rechtswidrigen Handlungen
            und Spam sowie der Sicherstellung der Integrität und Stabilität der
            Divilytics Plattform verarbeitet und genutzt. Die Standortdaten
            werden erhoben, verarbeitet und genutzt, damit der Nutzer den
            sogenannten Location Based Service nutzen kann, mit welchen ihm ab
            Registrierung Vorschläge geboten werden, die auf den jeweiligen
            Standort zugeschnitten sind. Näheres zur Nutzung der Standortdaten
            siehe auch unter den Punkten c.3 und c.3. Weiterhin nutzt Divilytics
            Cookies (siehe Punkt d.1), Analysedienste und Trackinganbieter
            (siehe Punkt d.2).
          </P>

          <H3 mt={50} bold>
            c. Wie werden meine Daten nach Erstellen eines Nutzerprofils und bei
            der Nutzung des Angebots der Divilytics-App bzw. Divilytics-Domain
            erhoben, verarbeitet und genutzt?
          </H3>
          <P>
            Die Divilytics verwendet die personenbezogenen Daten, die der Nutzer
            mitteilt oder die bei der Nutzung von Divilytics nach Erstellen
            eines Nutzerprofils anfallen, ohne gesonderte Einwilligung
            ausschließlich zur Durchführung des Nutzungsverhältnisses und wie
            entsprechend in dieser Datenschutzerklärung dargestellt. Für eine
            Nutzung von Divilytics im vollem Umfang ist es notwendig, dass sich
            der Nutzer ein Nutzerprofil erstellt. Dazu muss der Nutzer weitere
            personenbezogene Daten angeben, die die Divilytics zur Erbringung
            der jeweiligen Leistung nutzt. c.1 Pflichtangaben bei der Erstellung
            des Nutzerprofils Bei der Erstellung des Nutzerprofils und
            Registrierung müssen oder können folgende Angaben gemacht werden:
            Geschlecht Alter Nutzername (Pseudonym, Nickname) Stadt
            E-Mail-Adresse Passwort Profilbild Die angegebenen Daten werden zum
            Zweck der Nutzung von Divilytics erhoben, verarbeitet und genutzt.
            Unter anderem dienen die Angaben zur Ansprache, zur
            Authentifizierung, zur Altersverifikation, zur Personalisierung des
            Profils, zur Anzeige anderer passender Nutzerprofile sowie für
            Werbezwecke (siehe auch Punkt d). c.2 Freiwillige Daten Der Nutzer
            kann in seinem Nutzerprofil eine Vielzahl freiwilliger Angaben
            machen. Dies dient insbesondere der Funktion von Divilytics,
            Empfehlungen für andere Nutzerprofile nach unterschiedlichen
            Kriterien für den Nutzer auszuwählen und innerhalb von Divilytics
            anzuzeigen. Durch zusätzliche Informationen erhöht sich die
            Wahrscheinlichkeit, mit passenden Nutzerprofilen in Kontakt zu
            kommen. Diese Angaben sind für den Nutzer freiwillig und können im
            Profil unter Details eingesehen bzw. eingetragen, geändert oder
            gelöscht werden. Die angegebenen Daten werden zum Zweck der Nutzung
            von Divilytics von uns erhoben, verarbeitet und genutzt. Diese
            freiwilligen Angaben sind für andere eingeloggte Nutzer innerhalb
            von Divilytics als „öffentlich" sichtbar, können aber vom
            Nutzerprofilinhaber jederzeit unter den Einstellungen innerhalb des
            Nutzerprofils gelöscht oder abgeändert werden. c.3 Standortdaten Das
            Angebot von Divilytics umfasst auch sogenannte Location Based
            Services, mit welchen dem Nutzer spezielle Angebote dargestellt
            werden, die auf den jeweiligen Standort zugeschnitten sind. Dadurch
            können dem Nutzer insbesondere andere Nutzer, die sich in dessen
            Nähe befinden, angezeigt werden. Um diese Funktionen innerhalb der
            Divilytics-App anbieten zu können, erhebt Divilytics Standortdaten
            mittels GPS des verwendeten Endgeräts sowie Standortdaten über
            nahegelegene Funkzugangsknoten. Wenn die Standorterhebung aktiv ist,
            wird der Standort bei Erlaubnis regelmäßig an die Divilytics
            übertragen und dort verarbeitet und genutzt. Der Nutzer kann diese
            Funktion in den Einstellungen des Betriebssystems auf seinem
            Endgerät jederzeit anpassen, d.h. das Erfassen der Standortdaten
            erlauben oder deren Nutzung widerrufen. c.4 IP-Adressen Die
            IP-Adressen der Nutzer werden zu Sicherheits- und Prüfzwecken
            aufgezeichnet. Damit soll ein Missbrauch des Dienstes verhindert
            werden. Die Daten werden zur Prävention und Bekämpfung von
            rechtswidrigen Handlungen und Spam sowie der Sicherstellung der
            Integrität und Stabilität der Divilytics Plattform verarbeitet und
            genutzt. c.5 Zahlungsdaten Wenn der Nutzer In-App-Käufe über
            Divilytics tätigt, also digitale Währungseinheiten kauft geschieht
            dies über externe Zahlungsanbieter. Von Divilytics werden bei einem
            Kauf keine Zahlungsdaten erhoben und verarbeitet. Die Eingabe sowie
            Verarbeitung der Zahlungsdaten erfolgt direkt bei den
            Zahlungsanbietern. Zwischen Divilytics und den Zahlungsanbietern
            werden technische Daten (u.a. die Transaktion ID) zur Validierung
            der Käufe ausgetauscht.
          </P>

          <H3 mt={50} bold>
            d. Welche Cookies, Trackinganbieter, Analysedienste nutzt
            Divilytics? Wie sind Social Media und Like-Buttons eingebunden?
          </H3>
          <P>
            d.1 Cookies und Cookie-Policy Um die Divilytics-Domain möglichst
            komfortabel nutzen zu können, setzen wir auf der Divilytics-Domain
            Cookies ein. Bei Cookies handelt es sich um kleine Textdateien, die
            lokal im Zwischenspeicher des Internet-Browsers (sog. Session
            Cookies) oder auf der Festplatte des verwendeten mobilen Endgerätes
            oder des Computers (sog. permanent Cookies) gespeichert werden. Die
            Browsereinstellungen können so gewählt werden, dass Cookies
            abgelehnt oder vor dem Setzen der Cookies auf diese hingewiesen
            wird. Durch das Setzen von Cookies werden den Nutzern
            pseudonymisierte IDs zugewiesen. Es werden durch Cookies keine
            Programme auf dem verwendeten Endgerät des Nutzers ausgeführt oder
            gar Viren übertragen. Session Cookies werden nach Ende der
            Browser-Sitzung aus dem Zwischenspeicher gelöscht. Divilytics
            verwendet Cookies auch, um die Sicherheit der Divilytics-Domain zu
            erhöhen. Beispielsweise zur Authentifizierung des Nutzers während
            einer Sitzung, zur Vermeidung von Cross-Site-Scripting oder zur
            Unterbindung von Phishing und betrügerischen Handlungen, wie
            Scamming. Weiterhin setzen Trackinganbieter/Analysedienste (siehe
            dazu auch die Ausführungen unter Punkt 4.2 Verwendung zum Einsatz
            von Trackinganbietern/Analysediensten) im Auftrag von Divilytics
            Cookies auf das Endgerät des Nutzers, um die Nutzungsdaten zu
            erfassen. Hierbei handelt es sich um solche Daten, die erhoben
            werden können, während der Nutzer sich z. B. auf den Seiten der
            Divilytics-Domain bewegt oder Werbung anklickt. Diese Cookies werden
            dazu verwendet, um zielgerichtete Werbung anzuzeigen, Berichte zu
            Werbekampagnen zu optimieren und um zu vermeiden, dass identische
            Werbung mehrfach angezeigt wird. Die Nutzer können auf den Einsatz
            der Cookies Einfluss nehmen. Die meisten Browser verfügen über eine
            Einstellungs-Option mit der das Speichern von Cookies eingeschränkt
            oder komplett verhindert wird. Allerdings weisen wir darauf hin,
            dass die Nutzung und insbesondere der Nutzungskomfort von Divilytics
            ohne Verwendung von Cookies eingeschränkt sein kann. d.2
            Trackinganbieter, Analysedienste Um die Leistungen von Divilytics zu
            optimieren und bestmöglich anbieten zu können, werden von Divilytics
            Analysen des Besucherverhaltens durchgeführt. Hierfür setzt die
            Divilytics Analyse-Verfahren ein, mit denen Besuche auf der
            Divilytics-Domain bzw. Divilytics-App analysiert werden können.
            Weiterhin kommen Trackingtools von Drittanbietern zum Einsatz, durch
            welche die Reichweite von unterschiedlichen Werbekampagnen und
            Marketing-Aktionen analysiert werden kann. Beim Einsatz von
            Drittanbieter-Tools können auch personenbezogene Daten übermittelt
            werden. Zweck der Datenverarbeitung ist neben der Fehlerbehebung vor
            allem die Optimierung von Divilytics im Hinblick auf die Bedürfnisse
            der Nutzer. Auch erhält die Divilytics über das Webanalyse-Verfahren
            Kennzahlen über das Besucheraufkommen und deren zeitliche
            Verteilung, über beliebte Inhalte sowie die Verweildauer von
            Nutzern. Es kann gegebenenfalls nachvollzogen werden, ob ein
            Nutzerprofil aufgrund einer Werbemaßnahme erstellt wurde. Die Daten
            werden zur Prävention und Bekämpfung von Fakeprofilen,
            rechtswidrigen Handlungen und Spam sowie der Sicherstellung der
            Sicherheit der Divilytics Plattform verarbeitet und genutzt. Zur
            Durchführung der Analyse werden Nutzerdaten an verschiedene
            Drittanbieter übermittelt. Die folgende Analyse- und
            Tracking-Anbieter kommen bei Divilytics derzeit zum Einsatz: Google
            Analytics Divilytics verwendet "Google Analytics", ein Angebot der
            Firma Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
            94043, USA (nachfolgend: „Google"). Die Divilytics weist darauf hin,
            dass bei der Nutzung von Divilytics Google Analytics um die
            IP-Masking Funktion „_gat. _anonymizeIp" erweitert wurde, um eine
            anonymisierte Erfassung von IP-Adressen zu gewährleisten.
            Nachfolgende Informationen zum Einsatz von Google Analytics sind zu
            beachten: Google Analytics verwendet Cookies, die auf dem Gerät
            gespeichert werden und die eine Analyse der Benutzung von Divilytics
            ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre
            Benutzung von Divilytics werden in der Regel an einen Server von
            Google in den USA übertragen und dort gespeichert. Durch Aktivierung
            der IP-Anonymisierung wird die IP-Adresse von Google jedoch
            innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
            zuvor gekürzt. Im Auftrag von Minnie Partners UG wird Google diese
            Informationen benutzen, um die Nutzung unseres Dienstes auszuwerten,
            um Reports über Aktivitäten zusammenzustellen und um weitere mit der
            Nutzung verbundene Dienstleistungen gegenüber dem Betreiber zu
            erbringen. Hiergegen hat der Nutzer eine Widerspruchsmöglichkeit:
            Der Datenerhebung und -speicherung durch Google Analytics kann
            jederzeit mit Wirkung für die Zukunft widersprochen werden. Der
            Nutzer hat dazu die Möglichkeit, ein von Google herausgegebenes
            Browser-Plugin zu installieren. Dieses steht für verschiedene
            Browser-Versionen zur Verfügung und kann hier heruntergeladen
            werden. Weitere Informationen zur Datennutzung zu Werbezwecken durch
            Google, Einstellungs- und Widerspruchsmöglichkeiten erfahren Sie auf
            den Webseiten von Google: („Datennutzung durch Google bei Ihrer
            Nutzung von Websites oder Apps unserer Partner"), („Datennutzung zu
            Werbezwecken"), („Informationen verwalten, die Google verwendet, um
            Ihnen Werbung einzublenden") und („Bestimmen Sie, welche Werbung
            Google Ihnen zeigt"). Adjust Divilytics nutzt das
            App-Nutzungs-Analyse-Tool "adjust", ein Produkt der Firma adjust
            GmbH, Saarbrücker Str. 37 a in 10405 Berlin, Deutschland. Wenn der
            Nutzer die Divilytics-App installiert, speichert adjust
            Installations- und Ereignis-Daten von der iOS oder Android App.
            Damit können wir verstehen, wie unsere Nutzer mit der Divilytics-App
            interagieren. Ferner können wir unsere mobilen Werbekampagnen
            dadurch analysieren und verbessern. Für diese Analyse nutzt adjust
            die IDFA oder Advertiser-ID, sowie deine anonymisierte IP- und MAC
            Adresse. Die Daten sind einseitig anonymisiert, d.h. es ist nicht
            möglich dich oder dein mobiles Gerät dadurch zu identifizieren. Wenn
            du kein Tracking durch adjust wünschst, wähle das bitte hier aus.
            Rapidmail Der Versand der Newsletter und anderer E-Mails erfolgt
            mittels des Versanddienstleisters rapidmail GmbH, Augustinerplatz 2,
            79098 Freiburg i.Br., Deutschland. Die Datenschutzbestimmungen des
            Versanddienstleisters können Sie hier einsehen. Der
            Versanddienstleister wird auf Grundlage unserer berechtigten
            Interessen gem. Art. 6 Abs. 1 lit. f DSGVO und eines
            Auftragsverarbeitungsvertrages gem. Art. 28 Abs. 3 S. 1 DSGVO
            eingesetzt. Google-Tag-Manager Wir benutzen den Google Tag Manager.
            Google Tag Manager ist eine Lösung, mit der Vermarkter Website-Tags
            über eine Oberfläche verwalten können. Das Tool Tag Manager selbst
            (das die Tags implementiert) ist eine cookielose Domain und erfasst
            keine personenbezogenen Daten. Das Tool sorgt für die Auslösung
            anderer Tags, die ihrerseits unter Umständen Daten erfassen. Google
            Tag Manager greift nicht auf diese Daten zu. Wenn auf Domain- oder
            Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese für
            alle Tracking-Tags bestehen, die mit Google Tag Manager
            implementiert werden. Google Adwords Wir nutzen das
            Online-Werbeprogramm "Google AdWords" und im Rahmen von Google
            AdWords das Conversion-Tracking. Das Google Conversion Tracking ist
            ein Analysedienst der Google Inc. (1600 Amphitheatre Parkway,
            Mountain View, CA 94043, USA; "Google"). Wenn Sie auf eine von
            Google geschaltete Anzeige klicken, wird ein Cookie für das
            Conversion-Tracking auf Ihrem Rechner abgelegt. Diese Cookies haben
            eine begrenzte Gültigkeit, enthalten keine personenbezogenen Daten
            und dienen somit nicht der persönlichen Identifizierung. Wenn Sie
            bestimmte Internetseiten unserer Website besuchen und das Cookie
            noch nicht abgelaufen ist, können Google und wir erkennen, dass Sie
            auf die Anzeige geklickt haben und zu dieser Seite weitergeleitet
            wurden. Jeder Google AdWords-Kunde erhält ein anderes Cookie. Somit
            besteht keine Möglichkeit, dass Cookies über die Websites von
            AdWords-Kunden nachverfolgt werden können. Die Informationen, die
            mit Hilfe des Conversion-Cookie eingeholt werden, dienen dazu,
            Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für
            Conversion-Tracking entschieden haben. Hierbei erfahren die Kunden
            die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und
            zu einer mit einem Conversion-Tracking-Tag versehenen Seite
            weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit
            denen sich Nutzer persönlich identifizieren lassen. Wenn Sie nicht
            am Tracking teilnehmen möchten, können Sie dieser Nutzung
            widersprechen, indem Sie die Installation der Cookies durch eine
            entsprechende Einstellung Ihrer Browser Software verhindern
            (Deaktivierungsmöglichkeit). Sie werden sodann nicht in die
            Conversion-Tracking Statistiken aufgenommen. Weiterführende
            Informationen sowie die Datenschutzerklärung von Google finden Sie
            hier.
          </P>

          <H3 mt={50} bold>
            e. Wie werden Daten offengelegt bzw. weitergegeben
          </H3>
          <P>
            Die Divilytics leitet personenbezogenen Daten der Nutzer nicht an
            Dritte weiter, es sei denn, es liegt eine Einwilligung des Nutzers
            dazu vor, die Übermittlung ist gesetzlich vorgeschrieben oder
            gesetzlich zulässig. e.1 Allgemeine Hinweise zur Weitergabe von
            personenbezogenen Daten Nutzerdaten und sonstige Daten Divilytics
            darf Kundendaten zur Vertragsdurchführung an Folgende Dritte
            weitergeben: Externe Serviceanbieter und Vertreter. Divilytics kann
            externe Unternehmen oder Personen mit der Verarbeitung von
            Kundendaten beauftragen. Verbundene Unternehmen. Divilytics kann
            verbundene Unternehmen mit der Verarbeitung von Kundendaten
            beauftragen. Sonstige Offenlegungen Divilytics darf Kundendaten wie
            folgt weitergeben bzw. offenlegen: Im Rahmen von Änderungen der
            Geschäftsstruktur. Im Zusammenhang mit Fusionen, Übernahmen,
            Insolvenzverfahren, Auflösungsverfahren, Restrukturierungsmaßnahmen,
            Veräußerungen eines Teils oder aller Vermögensgegenstände von
            Divilytics, Finanzierungen, Übernahmen eines Teils oder unseres
            gesamten Geschäfts, ähnlichen Transaktionen oder Verfahren oder
            Schritten in Erwägung derartiger Aktivitäten (z. B. Due Diligence).
            Im Rahmen der gesetzlichen Vorschriften. Zur Einhaltung gesetzlicher
            Bestimmungen oder behördlicher Auflagen und in Reaktion auf
            rechtmäßige Anfragen, Gerichtsbeschlüsse und Rechtsverfahren. Im
            Rahmen der Durchsetzung der Rechte von Divilytics, zur Verhinderung
            von Betrug und zur Gewährleistung der Sicherheit. Zum Schutz und zur
            Verteidigung der Rechte, des Eigentums bzw. der Sicherheit von
            Divilytics oder Dritten, einschließlich der Durchsetzung von
            Verträgen und Richtlinien, sowie im Zusammenhang mit der
            Untersuchung und Verhinderung von Betrug. Divilytics darf
            aggregierte oder um personenbezogene Merkmale bereinigte Daten für
            jeden Zweck verwenden. Divilytics kann z. B. aggregierte oder um
            personenbezogene Merkmale bereinigte Daten an Partner oder andere
            für geschäftliche oder Analysezwecke weitergeben, indem Divilytics
            etwa veröffentlicht wie viele Nachrichten über Divilytics an einem
            Tag versenden werden. e.2 Kategorien von Datenempfängern Divilytics
            nutzt derzeit externe Datenauftragsverarbeiter für die folgenden
            Dienste: Services zum Versand von E-Mail-Benachrichtigungen (z.B.
            Rapidmail) Analyse und Tracking Dienste (siehe Punkt 4.2) Abhängig
            von der Art der bereitgestellten Dienstleistungen kann Divilytics
            auch verbundene Unternehmen als Datenauftragsverarbeiter für die
            Bereitstellung einiger oder aller dem Kunden gebotenen Services
            hinzuziehen.
          </P>

          <H3 mt={50} bold>
            f. Wie läuft die Datenlöschung und das Erstellen von Backups ab?
          </H3>
          <P>
            Mit der Löschung des Nutzerprofils (Achtung: Nicht durch Löschung
            der Divilytics-App!) bzw. entsprechender Benachrichtigung an uns,
            werden alle erhobenen Daten des Nutzers gelöscht. Davon ausgenommen
            bleiben Daten, die aufgrund gesetzlicher Vorschriften oder zur
            Vertragsabwicklung vorgehalten werden müssen. Darüber hinaus kann
            der Nutzer zu jeder Zeit die Löschung von einzelnen Daten im Profil
            selbst vornehmen bzw. bei uns über eine entsprechende
            Benachrichtigung beantragen. Eine Löschung aus den
            Produktiv-Datenbanken und somit aus der Oberfläche von Divilytics
            erfolgt innerhalb von 48 Stunden. Die Divilytics behält es sich vor,
            Profil-Dateien vor einer Löschung zu überprüfen und gegebenenfalls
            zu sichern, wenn bei den Nutzungsprofilen aufgrund bestimmter
            Tatsachen der Verdacht besteht, dass diese Divilytics gesetzes- oder
            vertragswidrig nutzen. Dies dient dem Schutz der Nutzer der Angebote
            der Divilytics . Unabhängig von der durch Profillöschung durch den
            Nutzer angestoßenen Löschung der Daten, löscht Divilytics
            historische Daten, die zur Vertragserfüllung nicht weiter benötigt
            werden (z.B. historische Standortdaten sowie IP-Daten) automatisiert
            in regelmäßigen Abständen.
          </P>

          <H3 mt={50} bold>
            g. Welche Maßnahmen zur Datensicherheit gibt es bei Divilytics?
          </H3>
          <P>
            Die Divilytics nimmt das Vertrauen der Nutzer und den Schutz der
            Daten äußerst wichtig. Um unbefugten Zugriff auf Nutzerdaten oder
            deren Preisgabe zu verhindern, um die Integrität der Daten zu
            bewahren und die angemessene Verwendung der Daten zu gewährleisten,
            setzt die Minnie Partners UG geeignete physische, technische und
            administrative Verfahren zum Schutz der von der Divilytics erhobenen
            Informationen ein. Für einen bestmöglichen Schutz der Daten des
            Nutzers erfolgt das Angebot der Divilytics-Domain bzw.
            Divilytics-App über eine gesicherte SSL Verbindung, d.h. die Daten
            werden sowohl zwischen den Servern und dem Browser als auch zwischen
            den Servern und der App verschlüsselt übertragen.
          </P>

          <H3 mt={50} bold>
            h. Rechte des Nutzers: Einwilligung und Widerruf, Auskunft,
            Berichtigung, Löschung, Übertragung
          </H3>
          <P>
            h.1 Einwilligung und Widerruf Im Rahmen der Registrierung bei
            Divilytics willigt der Nutzer ein, dass seine personenbezogenen
            Daten nach Maßgabe dieser Datenschutzerklärung und gemäß der AGB
            erhoben, gespeichert und verarbeitet werden. Diese Einwilligung muss
            der Nutzer bei Erstellung des Nutzerprofils ausdrücklich erklären.
            Diese Einwilligung wird ebenso wie der gesamte Vorgang der
            Erstellung des Nutzerprofils von Divilytics protokolliert. Eine
            Einwilligung kann der Nutzer jederzeit mit Wirkung für die Zukunft
            widerrufen. Dies kann erfolgen durch Löschung des Nutzerprofils
            (nicht der Divilytics-App), an den angegebenen Stellen in der
            Divilytics-App bzw. Divilytics-Domain innerhalb des Nutzerprofils
            oder per E-Mail an uns: E-Mail: info@Divilytics.io. h.2 Auskunft,
            Berichtigung, Löschung, Übertragung Der Nutzer hat nach den
            geltenden Gesetzen einen Anspruch auf unentgeltliche Auskunft über
            die von ihm gespeicherten personenbezogenen Daten sowie
            gegebenenfalls ein Recht auf Berichtigung und/oder Löschung bzw.
            Übertragung dieser Daten. Der Nutzer kann sich dazu z.B. per E-Mail
            an info@Divilytics.io wenden.
          </P>

          <H3 mt={50} bold>
            i. Änderung der Datenschutzerklärung
          </H3>
          <P>
            Die Divilytics wird diese Datenschutzerklärung gegebenenfalls
            aktualisieren. Die Verwendung der Nutzer-Daten unterliegt der
            jeweils aktuellen Version, diese kann jederzeit unter
            getDivilytics.com abgerufen werden.
          </P>

          <H3 mt={50} bold>
            j. Fragen zum Datenschutz
          </H3>
          <P>
            Sollten Sie noch Fragen oder Bedenken zum Datenschutz haben, so
            wenden Sie sich bitte an: info@Divilytics.io
            <BR />
            Stand, April 2020
          </P>

          <H3 mt={50} bold></H3>
          <P></P>

          <H3 mt={50} bold></H3>
          <P></P>

          <H3 mt={50} bold></H3>
          <P></P>

          <H3 mt={50} bold></H3>
          <P></P>
        </Wrapper>
      )}

      {mode === "terms" && locale === "de" && (
        <Wrapper mt={20}>
          <P mt={50}>
            Bitte lesen Sie die Nutzungsbedingungen aufmerksam durch, bevor Sie
            Divilytics (der „Service“) nutzen.
            <BR />
            Der Zugriff und die Benutzung des Services setzen Ihre Zustimmung zu
            den im Folgenden aufgelisteten Nutzungsbedingungen voraus. Mit der
            Benutzung unseres Services bestätigen Sie Ihr Einverständnis zu
            diesen Bedingungen. Falls Sie mit (Teilen) der Nutzungsbedingungen
            nicht einverstanden sind, haben Sie keine Genehmigung den Service zu
            benutzen.
          </P>
          <H3 mt={50} bold>
            Abonnements / Subscriptions
          </H3>
          <P mt={10}>
            Die Pro-Version der App, Divilytics Pro, ist für 1.99€/Monat oder
            14,99€/Jahr (bzw. einer entsprechenden Währung) verfügbar. Während
            eines aktiven Abonnement erhält der User unbeschränkten Zugriff auf
            alle Funktionalitäten dieser Applikation. – Nach Bestätigung des
            Einkaufes wird der iTunes Account oder die Kreditkarte des Users mit
            dem entsprechenden Betrag belastet. – Das Abonnement kann über das
            iTunes-Konto des Users verwaltet und bezahlt werden. – Das
            Abonnement wird automatisch verlängert, wenn es nicht spätestens 24
            Stunden vor Ablauf gekündigt wird. – Das iTunes-Konto des Users wird
            innerhalb von 24 Stunden vor Ende der laufenden Periode mit dem
            neuen Betrag belastet. Bei einem 1 Monats Abo verlängert sich die
            Laufzeit der Premium Version um einen weiteren Monat und bei einem 1
            Jahres Abo verlängert sich die Laufzeit der Premium Version um ein
            weiteres Jahr. Im Falle einer Verlängerung des Abonnements wird der
            Preis der ersten Periode für jede folgende Periode erneut berechnet
            und bleibt unverändert. – Abonnements können verwaltet und die
            automatische Verlängerung ausgeschalten / bzw. gekündigt werden
            indem der User nach dem Einkauf die Account Einstellungen (in
            iTunes) aufruft. – Jeder nicht genutzte Teil eines kostenlosen
            Testzeitraum (falls angeboten) verfällt, sobald der User ein
            Abonnement für diese Applikation abschließt, falls dies zutreffend
            ist.
          </P>
          <P mt={10}>
            Falls Sie Fragen zu diesen Nutzungsbedingungen haben kontaktieren
            Sie uns bitte unter hallo@kations.de
          </P>
          <P mt={10}>Last Update: April, 2020</P>
        </Wrapper>
      )}

      {mode === "terms" && locale !== "de" && (
        <Wrapper mt={20}>
          <P mt={50}>
            Please read the Terms of Use carefully before using Divilytics (the
            "Service").
            <BR />
            Access and use of the Service constitutes your consent to the terms
            of use listed below. With the use of our service you confirm your
            agreement to these conditions. If you agree with (parts of) the
            terms of use disagree, you do not have permission to use the Service
            use.
          </P>
          <H3 mt={50} bold>
            Abonnements / Subscriptions
          </H3>
          <P mt={10}>
            The Pro version of the app, Divilytics Pro, is available for
            1.99€/month or 14.99€/year (or equivalent currency) available.
            During of an active subscription, the user receives unlimited access
            to all functionalities of this application. - After confirmation of
            the purchase, the iTunes account or the credit card of the user will
            be charged charged with the corresponding amount. - The subscription
            can be cancelled via the Account (iTunes) of the user can be managed
            and paid. - The Subscription is automatically renewed if it is not
            cancelled at the latest 24 hours before the expiry of the contract.
            - The user's Account will be charged within 24 hours before the end
            of the current period with the new amount. With a 1 month
            subscription the Premium Version gets renewed one additional month
            and with a 1 year subscription, the Premium Version gets renewed one
            additional year. In case of a renewal of the subscription the Price
            of first period recalculated for each subsequent period and remains
            unchanged. - Subscriptions can be managed and the automatic renewal
            can be switched off / or cancelled by the user after purchasing in
            the account settings (iTunes). - Any unused portion of a free Test
            period (if offered) expires as soon as the user has entered a
            subscription to this application, if applicable is.
          </P>
          <P mt={10}>
            If you have any questions regarding these terms of use, please
            contact please contact us at hallo@kations.de
          </P>
          <P mt={10}>Last Update: April, 2020</P>
        </Wrapper>
      )}
    </Page>
  );
}
