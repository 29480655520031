import * as React from "react";
import { Flex, P, B, color, useTheme, Group, Button, Animate } from "unikit";
import dayjs from "dayjs";

import { useTranslation, useAppState } from "../AppContext";
import Chart from "./Chart";

export default ({
  data = [],
  objkey,
  label,
  barColor = "primary",
  formatV,
  formatValue,
  ...rest
}) => {
  const [mode, setMode] = React.useState("yearly");
  const theme = useTheme();
  const { t } = useTranslation();
  const { formatPrice } = useAppState();
  const chartData = [
    ...data[mode].slice(0, 7).map((d) => ({
      value: formatV ? formatV(d[objkey]) : d[objkey],
      growth: formatV ? formatV(d[`${objkey}Growth`]) : d[`${objkey}Growth`],
      label: dayjs(d.date).format(mode === "yearly" ? "YYYY" : "MMM YY"),
    })),
  ].reverse();
  const [index, setIndex] = React.useState(chartData.length - 1);

  return (
    <Animate pb={30} w="100%">
      <Flex
        py={7}
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        row
      >
        <P font="label" color="primary" ml={5} uppercase>
          {t(label)}
        </P>
        <Group ml={7} w={115}>
          <Button
            light={mode !== "yearly"}
            rounded
            onPress={() => setMode("yearly")}
            size={22}
          >
            Yearly
          </Button>
          <Button
            light={mode !== "quarterly"}
            rounded
            onPress={() => setMode("quarterly")}
            size={22}
          >
            Quarterly
          </Button>
        </Group>
      </Flex>

      <Flex
        w="100%"
        h={130}
        bg="surface"
        borderTopWidth={1}
        borderRightWidth={1}
        borderLeftWidth={1}
        borderColor="text"
        borderColorAlpha={0.05}
        borderRadius={15}
        paddingTop={10}
        {...rest}
      >
        <Flex absolute l={13} t={13} alignItems="center" row>
          <Flex w={12} h={12} mr={5} borderRadius={6} bg={barColor} />
          <P font="label">
            {`${t(label)} ${chartData[index].label} `}
            <B font="label">{`${formatPrice({
              value: chartData[index].value,
              mrd: true,
            })}`}</B>
          </P>
        </Flex>
        <Chart
          data={chartData}
          barColor={color(theme.colors[barColor] || barColor)
            .setAlpha(0.1)
            .toRgbString()}
          selectedBarColor={barColor}
          selected={index}
          formatValue={formatValue}
          height={70}
          gridWidth={1}
          gridColor={barColor}
          gridOpacity={0.25}
          rotateValue={false}
          valueOffset={-19}
          onPress={(bar) => setIndex(bar.index)}
          xAxisProps={{
            style: {
              paddingVertical: 2,
            },
          }}
          showValue
          xAxis
        />
      </Flex>
    </Animate>
  );
};
