import * as React from "react";
import dayjs from "dayjs";
import { P, H5, Button, Icon, Dropdown, Flex, useTheme, Tabs } from "unikit";

import { Page, Wrapper, DepotOverview, FinanceChart } from "../components";
import { useTranslation, useAppState } from "../AppContext";
import { useQuery, STOCK_QUERY, DEPOT_QUERY } from "../Apollo";
import {
  getDividends,
  getCurrencyChange,
  getEvents,
  cleanName,
} from "../stocklytics/lib/helper";
import { Financials, Highlights } from "./StockItems";

export default function App({
  route: { params },
  navigation: { navigate, goBack },
}) {
  const theme = useTheme();
  const [tab, setTab] = React.useState("Highlights");
  const { user, formatPrice } = useAppState();
  const { slug } = params;
  const { data = {}, loading, error } = useQuery(STOCK_QUERY, {
    variables: {
      filter: { _id: slug },
    },
  });
  const { stock } = data;
  const { t } = useTranslation();

  //console.log({ stock, loading, slug, error });

  if (loading || !stock) {
    return <Page loading></Page>;
  }

  const events = getEvents({ stocks: [stock] });
  console.log({ events });

  const renderDepots = ({ depots, close }) => {
    return depots.map((depot, index) => (
      <Button
        onPress={() => {
          close();
          var holdings = [...depot.holdings];
          const found = holdings.find((h) => h.symbol === stock.symbol);
          if (found) {
            theme.alert({
              type: "warning",
              message: t("stock.inHoldings"),
            });
            holdings = holdings.sort((x, y) => {
              return x.symbol === found.symbol
                ? -1
                : y.symbol === found.symbol
                ? 1
                : 0;
            });
          } else {
            holdings = [
              {
                symbol: stock.symbol,
                amount: 1,
                name: stock.name,
              },
              ...holdings,
            ];
          }
          navigate("Form", {
            _id: depot._id,
            title: `${depot.name} Holdings`,
            defaultDoc: { holdings },
            type: "Depot",
            mode: "update",
            fields: ["holdings"],
            refetchQueries: (doc) => [
              "user",
              {
                query: DEPOT_QUERY,
                variables: {
                  filter: { _id: depot._id },
                },
              },
            ],
          });
        }}
        w="100%"
        mr={5}
        my={4}
        key={index}
        size={44}
        light
      >
        {depot.name}
      </Button>
    ));
  };

  return (
    <Page
      title={stock.name}
      goBack={goBack}
      rightAction={
        <Dropdown
          backdrop
          wrapperProps={{
            w: 300,
            r: 0,
            shadow: 60,
            t: "140%",
          }}
          content={({ close }) => (
            <Flex w="100%">
              <P pt={0} px={5}>
                {t("common.depots")}
              </P>
              <Flex w="100%" px={3}>
                {renderDepots({
                  depots: user.depots.filter((d) => d.watchlist !== true),
                  close,
                })}
              </Flex>
              <P pt={0} px={5}>
                {t("common.watchlists")}
              </P>
              <Flex w="100%" px={3}>
                {renderDepots({
                  depots: user.depots.filter((d) => d.watchlist === true),
                  close,
                })}
              </Flex>
            </Flex>
          )}
        >
          <Button size={30} bg="text" light rounded>
            <Icon name="plus" color="#FFF" size={20} />
          </Button>
        </Dropdown>
      }
      titleColor="#FFF"
      spacerProps={{ bg: "primary" }}
    >
      <DepotOverview
        dividends={getDividends({
          dividends: stock.dividends || [],
          mode: "yearly",
          from: dayjs().subtract(12, "years").startOf("year").toDate(),
          to: dayjs().endOf("year").toDate(),
          currency: stock ? stock.currency : "USD",
          userCurrency: user
            ? user.config.currency
            : stock
            ? stock.currency
            : "USD",
          config: user ? user.config : undefined,
        })}
        dividendsByMonth={getDividends({
          dividends: stock.dividends || [],
          mode: "monthly",
          from: dayjs().startOf("year").toDate(),
          to: dayjs().endOf("year").toDate(),
          currency: stock ? stock.currency : "USD",
          userCurrency: user
            ? user.config.currency
            : stock
            ? stock.currency
            : "USD",
          config: user ? user.config : undefined,
        })}
        chart={stock.chart}
        payouts={stock.data.SplitsDividends.count || 0}
        stock
        price={formatPrice({
          value: getCurrencyChange({
            value: stock.price,
            currency: stock ? stock.currency : "USD",
            userCurrency: user
              ? user.config.currency
              : stock
              ? stock.currency
              : "USD",
            config: user ? user.config : undefined,
          }),
          suffix: true,
        })}
        cap={formatPrice({
          value: getCurrencyChange({
            value: stock.data.Highlights.MarketCapitalization,
            currency: stock ? stock.currency : "USD",
            userCurrency: user
              ? user.config.currency
              : stock
              ? stock.currency
              : "USD",
            config: user ? user.config : undefined,
          }),
          mrd: true,
          suffix: true,
        })}
      />
      <Wrapper mb={6}>
        <Tabs
          options={["Highlights", "Financials", "Events"]}
          bg="transparent"
          tabSize={44}
          tabProps={{ bg: "transparent", font: "p" }}
          indicatorProps={{ t: "auto", b: -1 }}
          activeColor="#FFF"
          borderRadius={0}
          indicatorSize={1}
          borderBottomWidth={1}
          borderColor="primaryLight"
          onChange={(v) => setTab(v)}
        />
      </Wrapper>

      {tab === "Highlights" ? (
        <Wrapper>
          <Highlights stock={stock} />
        </Wrapper>
      ) : null}
      {tab === "Financials" ? (
        <Wrapper>
          <Financials stock={stock} />
        </Wrapper>
      ) : null}
      {tab === "Events" ? (
        <Wrapper>
          {events.map((event, index) => {
            return (
              <Flex
                key={`event-${index}`}
                w="100%"
                bg="surface"
                borderTopWidth={1}
                borderRightWidth={1}
                borderLeftWidth={1}
                borderColor="text"
                borderColorAlpha={0.05}
                shadow={2}
                borderRadius={15}
                p={20}
                mt={10}
              >
                <Flex row justifyContent="space-between">
                  <Flex maxWidth="70%">
                    <H5>{dayjs(event.date).format("DD MMM YYYY")}</H5>
                    <P color="primary">{`${t(`events.${event.type}`)}`}</P>
                  </Flex>

                  {event.value ? (
                    <Flex>
                      <H5 textAlign="right" bold>
                        {formatPrice({
                          value: event.value,
                          suffix: true,
                        })}
                      </H5>
                      {event.growth && event.growth !== 0 ? (
                        <P
                          color={event.growth > 0 ? "primary" : "error"}
                          textAlign="right"
                        >{`${event.growth > 0 ? "+" : ""}${(
                          event.growth * 100
                        ).toFixed(2)}%`}</P>
                      ) : null}
                    </Flex>
                  ) : null}
                </Flex>
              </Flex>
            );
          })}
        </Wrapper>
      ) : null}
    </Page>
  );
}
