import React from "react";
import {
  isWeb,
  styled,
  withTheme,
  Flex,
  H2,
  H3,
  P,
  useSpring,
  AnimatedView,
  Button,
  Icon,
} from "unikit";
import { useSafeArea } from "react-native-safe-area-context";

import Wrapper from "./Wrapper";

const getProgress = (a, b, v) => {
  return (v - a) / (b - a);
};

const Header = styled.View({
  width: "100%",
  height: "auto",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "flex-end",
});

const Background = styled(AnimatedView)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 10,
});

export default withTheme(
  ({
    title,
    titleColor,
    desc,
    leftAction,
    rightAction,
    goBack,
    navComponent,
    renderFooter,
    theme,
    maxWidth,
    top = 0,
    modal = false,
  }) => {
    const insets = useSafeArea();
    var progress = getProgress(0, 100, top);
    if (progress > 0.5) progress = 0.5;

    const y = useSpring({
      to: -(progress * 50 > 15 ? 15 : progress * 50),
    });

    const o = useSpring({
      to: 0 + progress * 2,
    });

    const s = useSpring({
      to: 1 - progress / 2,
    });

    const TitleComp = H3;

    return (
      <AnimatedView
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          zIndex: 100,
          right: 0,
          transform: [{ translateY: y }],
        }}
      >
        <Header pt={modal ? 10 : insets.top}>
          <Background bg="primary" style={{ opacity: o }} zIndex={10} />
          {modal && (
            <Flex w="100%" flexCenter absolute l={0} t={insets.top + 5}>
              <Flex w={60} h={3} borderRadius={5} bg="primaryLight" />
            </Flex>
          )}
          <Flex w="100%" zIndex={100}>
            <Wrapper
              pb={10}
              pt={15}
              style={maxWidth ? { maxWidth: maxWidth } : null}
              relative
              px={modal && isWeb ? 0 : 10}
            >
              {navComponent ? (
                <Flex row w="100%">
                  {goBack ? (
                    <Button
                      onPress={() => {
                        goBack();
                      }}
                      bg={modal ? "primaryLight" : "text"}
                      light
                      rounded
                      h="100%"
                      mr={5}
                    >
                      <Icon name="arrowLeft" color="#FFF" size={20} />
                    </Button>
                  ) : null}
                  {navComponent}
                </Flex>
              ) : (
                <Flex
                  row
                  justifyContent="space-between"
                  h={desc ? 50 : 40}
                  alignItems="center"
                >
                  <Flex row>
                    {goBack ? (
                      <Button
                        onPress={() => {
                          goBack();
                        }}
                        bg={"surface"}
                        rounded
                        mr={7}
                        size={30}
                      >
                        <Icon
                          name={modal ? "arrowDown" : "arrowLeft"}
                          color="primary"
                          size={20}
                        />
                      </Button>
                    ) : null}
                    {goBack && theme.isMobile ? null : leftAction}
                  </Flex>
                  <Flex absoluteFill px={60} flexCenter pointerEvents="none">
                    <AnimatedView
                      style={{
                        transform: [{ scale: modal ? 1 : s }],
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {desc ? (
                        <P font="label" uppercase>
                          {desc}
                        </P>
                      ) : null}
                      <TitleComp
                        color={titleColor || "text"}
                        bold
                        textAlign="center"
                        numberOfLines={2}
                      >
                        {title}
                      </TitleComp>
                    </AnimatedView>
                  </Flex>
                  <Flex>{rightAction}</Flex>
                </Flex>
              )}
            </Wrapper>
          </Flex>
        </Header>
      </AnimatedView>
    );
  }
);
