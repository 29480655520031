import * as React from "react";
import { Flex, styled, Animate, P, H5, Grid, getObjValue } from "unikit";
import dayjs from "dayjs";

import { useTranslation, useAppState } from "../../AppContext";

const Box = styled.View(({ active }) => ({
  width: "100%",
  height: "auto",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "surface",
  borderRadius: 7,
  borderWidth: 2,
  borderColor: active ? "primary" : "surface",
  padding: 15,
  height: 90,
}));

function isNumber(n) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

const getValue = ({ stock, item, user, t }) => {
  var val = getObjValue(stock.data, item.obKey, item.suffix);

  if (val === undefined || val === null) {
    val = "-";
  }

  let suffix = item.suffix ? item.suffix : "";

  if (item.format) {
    val = item.format(val, t);
  }

  if (item.steady) {
    steady = getObjValue(stock.data, item.steady);
  }

  if (val > 100) {
    val = Math.floor(val);
  }

  if (suffix === "%") {
    val = val * 100;
  }

  if (item.change) {
    val = getCurrencyChange({
      value: val,
      currency: stock ? stock.currency : "USD",
      userCurrency: user
        ? user.config.currency
        : stock
        ? stock.currency
        : "USD",
      config: user ? user.config : undefined,
    });
  }

  if (isNumber(val) && val !== "NaN") {
    val = parseFloat(val.toFixed(2));
  }

  if (val.toString() === "NaN") val = 0;
  return `${val}${suffix}`;
};

const keys = [
  {
    key: "Growth3Y",
    obKey: "SplitsDividends.Growth3Y",
    suffix: "%",
  },
  { key: "PayoutRatio", obKey: "SplitsDividends.PayoutRatio", suffix: "%" },
  {
    key: "ForwardAnnualDividendYield",
    obKey: "SplitsDividends.ForwardAnnualDividendYield",
    suffix: "%",
  },
  {
    key: "GrowthYears",
    obKey: "SplitsDividends.GrowthYears",
    format: (v, t) => `${v} ${t("common.years")}`,
  },
  {
    key: "ExDividendDate",
    obKey: "SplitsDividends.ExDividendDate",
    format: (v) => dayjs(v).format("DD.MM.YYYY"),
  },
  {
    key: "DividendDate",
    obKey: "SplitsDividends.DividendDate",
    format: (v) => dayjs(v).format("DD.MM.YYYY"),
  },
  {
    key: "PDGRatio",
    obKey: "SplitsDividends.PDGRatio",
  },
  {
    key: "PEGRatio",
    obKey: "Financials.PEGRatio",
  },
];

export default function App({ stock }) {
  const { t } = useTranslation();
  const { user, formatPrice } = useAppState();
  //   return (
  //     <>
  //       {keys.map((item, index) => {
  //         return (
  //           <Animate key={`${index}`}>
  //             <Flex
  //               row
  //               justifyContent="space-between"
  //               borderBottomWidth={1}
  //               borderColor="primaryLight"
  //             >
  //               <P color="primary" textAlign="center">
  //                 {t(`${item.obKey}`)}
  //               </P>
  //               <H5 font="p" fontWeight="bold" mt={3} numberOfLines={1}>
  //                 {getValue({ stock, item, user, t })}
  //               </H5>
  //             </Flex>
  //           </Animate>
  //         );
  //       })}
  //     </>
  //   );
  return (
    <Grid min={150} gap={10}>
      {keys.map((item, index) => {
        return (
          <Animate key={`${index}`}>
            <Box shadow={2}>
              <P font="label" color="primary" textAlign="center" uppercase>
                {t(`${item.obKey}`)}
              </P>
              <H5 fontWeight="bold" mt={3} numberOfLines={1}>
                {getValue({ stock, item, user, t })}
              </H5>
            </Box>
          </Animate>
        );
      })}
    </Grid>
  );
}
