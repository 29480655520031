import * as React from "react";
import { Icon } from "unikit";

import {
  Page,
  List,
  DepotOverview,
  DepotItem,
  NoContent,
  Link,
} from "../components";
import { useTranslation, useAppState } from "../AppContext";
import { mergeDepotData } from "../stocklytics/lib/helper";

export default function App({ navigation: { navigate } }) {
  const { user } = useAppState();
  const { t } = useTranslation();

  const { depots = [] } = user || {};

  const { chart, dividends, dividendsByMonth } = React.useMemo(
    () =>
      mergeDepotData({ depots: depots.filter((d) => d.watchlist !== true) }),
    [depots]
  );

  return (
    <Page
      title={t("depot.my_depots")}
      titleColor="#FFF"
      spacerProps={{ bg: "primary" }}
    >
      <DepotOverview
        dividends={dividends}
        dividendsByMonth={dividendsByMonth}
        chart={chart.sort((a, b) => a.date > b.date)}
      />

      {depots.length === 0 ? (
        <NoContent
          title={t("user.empty")}
          desc={t("user.empty_desc")}
          mt={-30}
          action={() => {
            navigate("Form", {
              title: t("depot.create"),
              defaultDoc: { currency: user.currency, public: false },
              type: "Depot",
              mode: "insert",
              refetchQueries: ["user"],
            });
          }}
        />
      ) : (
        <>
          <List
            itemSize={242}
            data={depots.filter((d) => d.watchlist !== true)}
            title={t("common.depots")}
            action={() =>
              navigate("Form", {
                title: t("depot.create"),
                defaultDoc: { currency: user.currency, public: false },
                type: "Depot",
                mode: "insert",
                refetchQueries: ["user"],
              })
            }
            actionTitle={<Icon name="plus" color="#FFF" size={18} />}
            noContentProps={{ noAni: true }}
            renderItem={({ item, index }) => {
              return (
                <Link to="Depot" params={{ slug: item._id }}>
                  <DepotItem
                    title={item.name}
                    chart={item.dividends}
                    desc={`${item.holdings ? item.holdings.length : 0} ${t(
                      "depot.holdings"
                    )}`}
                  />
                </Link>
              );
            }}
          />

          <List
            itemSize={242}
            data={depots.filter((d) => d.watchlist === true)}
            title={t("common.watchlists")}
            action={() =>
              navigate("Form", {
                title: t("depot.create"),
                defaultDoc: {
                  currency: user.currency,
                  public: false,
                  watchlist: true,
                },
                type: "Depot",
                mode: "insert",
                refetchQueries: ["user"],
              })
            }
            actionTitle={<Icon name="plus" color="#FFF" size={18} />}
            noContentProps={{ noAni: true }}
            renderItem={({ item, index }) => {
              return (
                <Link to="Depot" params={{ slug: item._id }}>
                  <DepotItem
                    title={item.name}
                    chart={item.dividends}
                    desc={`${item.holdings ? item.holdings.length : 0} ${t(
                      "depot.holdings"
                    )}`}
                  />
                </Link>
              );
            }}
          />
        </>
      )}
    </Page>
  );
}
