import * as React from "react";
import { styled } from "unikit";

export default styled.View({
  maxWidth: 1100,
  width: "100%",
  margin: "0 auto",
  paddingHorizontal: 10,
  web: {
    width: "95%",
  },
});
