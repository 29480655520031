import dayjs from "dayjs";

export const Sectors = {
  Technology: ["Technology", "Information Technology"],
  Healthcare: ["Healthcare", "Health Care"],
  Services: ["Communication Services", "Services"],
  Consumer: ["Consumer Goods", "Consumer Cyclical", "Consumer Defensive"],
  Financial: ["Financial", "Financial Services"],
  Industrial: ["Basic Materials", "Industrial Goods", "Industrials"],
  Healthcare: ["Healthcare", "Health Care"],
  RealEstate: ["Real Estate", "Property"],
};

export const buildQuery = ({ user, sortKey = "growthScore" }) => {
  const { strategy = {}, isPro, isDiviPro } = user;
  const { limit = 20 } = strategy;
  const filter = {};
  const sort = { limit };
  sort["sort"] = { [sortKey]: -1 };
  if (strategy.sectors.length > 1 || strategy.sectors[0] !== "all") {
    const sectorFilter = [];
    strategy.sectors.map((sector) => {
      Sectors[sector].map((s) => sectorFilter.push(s));
    });
    filter["data.General.Sector"] = { $in: sectorFilter };
  }

  const capFlter = { $gte: 300000000 };
  const foundS = strategy.marketCap.find((c) => c === "s");
  const foundM = strategy.marketCap.find((c) => c === "m");
  const foundL = strategy.marketCap.find((c) => c === "l");
  if (!foundL) {
    if (foundS) {
      capFlter["$lte"] = 5000000000;
    }
    if (foundM) {
      capFlter["$lte"] = 50000000000;
    }
  }
  if (!foundS) {
    if (foundL) {
      capFlter["$gte"] = 50000000001;
    }
    if (foundM) {
      capFlter["$gte"] = 5000000001;
    }
  }
  filter["data.Highlights.MarketCapitalization"] = capFlter;

  return { filter, sort };
};

export const getObjValue = (o, s, suffix) => {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  if (suffix === "%" && typeof o === "number") {
    o = o * 100;
  }
  if (typeof o === "number") {
    o = parseFloat(o.toFixed(2));
  }
  return o;
};

export const mergeDepotData = ({ depots }) => {
  const merged = { chart: [], dividends: [], dividendsByMonth: [] };
  depots.map((depot) => {
    const keys = ["chart", "dividends", "dividendsByMonth"];
    keys.map((key) => {
      if (depot[key]) {
        depot[key].map((item) => {
          let found = merged[key].find((d) => d.label === item.label);
          if (found) {
            found.value = parseFloat((found.value + item.value).toFixed(2));
          } else {
            merged[key].push(Object.assign({}, item));
          }
        });
      }
    });
  });
  return merged;
};

export const cleanName = (name) => {
  if (!name) return "";
  return name
    .replace(",", "")
    .replace(" Incorporated", "")
    .replace(" Corporation", "")
    .replace(" Holdings", "")
    .replace(" Limited", "")
    .replace(" Corp", "")
    .replace(" S.A", "")
    .replace(" plc", "")
    .replace(" Inc ", "")
    .replace(" N.V", "")
    .replace(" Ltd", "")
    .replace(" AG", "")
    .replace(" SE", "")
    .replace(" NV", "")
    .replace(" S.p.A", "")
    .replace(" inc", "")
    .replace(", Inc", "")
    .replace(" A/S", "");
};

export const currencySymbols = {
  EUR: "€",
  USD: "$",
};

export const getCurrencySymbol = (currency) => {
  return currencySymbols[currency] || "€";
};

export const getCurrencyChange = ({
  value,
  currency,
  userCurrency,
  config,
}) => {
  //console.log({ value, currency, userCurrency, config });
  if (!value) return 0;
  if (currency === userCurrency) {
    return parseFloat(value.toFixed(2));
  } else if (
    config &&
    config.currencyChange &&
    config.currencyChange[currency] &&
    config.currencyChange[currency][userCurrency]
  ) {
    const rate = config.currencyChange[currency][userCurrency];
    const returnValue = parseFloat((value * rate).toFixed(2));
    return returnValue;
  } else {
    return parseFloat(value.toFixed(2));
  }
};

export const getEvents = ({
  stocks = [],
  period = "month",
  date,
  currency = "EUR",
  config = {},
}) => {
  date = date || dayjs().startOf(period).toDate();
  const adder = period === "year" ? 1 : 2;
  const range = dayjs(date).add(adder, period).endOf("day").toDate();
  const events = [];
  stocks.map((stock) => {
    if (stock.dividends) {
      const amount = stock.amount || 1;
      if (stock.earnings) {
        const filteredEarnings = stock.earnings.filter(
          (d) =>
            d.report_date &&
            dayjs(d.report_date).toDate() <= range &&
            dayjs(d.report_date).toDate() >= date
        );
        filteredEarnings.map((event) => {
          events.push({
            title: stock.name,
            date: event.report_date || event.date,
            type: "earnings",
          });
        });
      }

      const filtered = stock.dividends.filter(
        (d) =>
          (dayjs(d.date).toDate() <= range && dayjs(d.date).toDate() >= date) ||
          (dayjs(d.paymentDate).toDate() <= range &&
            dayjs(d.paymentDate).toDate() >= date) ||
          (dayjs(d.recordDate).toDate() <= range &&
            dayjs(d.recordDate).toDate() >= date)
      );
      filtered.map((d) => {
        if (
          !d.recordDate &&
          dayjs(d.date).toDate() <= range &&
          dayjs(d.date).toDate() >= date
        ) {
          events.push({
            title: stock.name,
            date: d.date,
            growth: d.growth,
            value: getCurrencyChange({
              value: amount * d.value,
              currency: stock.currency,
              userCurrency: currency,
              config,
            }),
            type: "dividend_ex",
          });
        }
        if (
          d.paymentDate &&
          dayjs(d.paymentDate).toDate() <= range &&
          dayjs(d.paymentDate).toDate() >= date
        ) {
          events.push({
            title: stock.name,
            date: d.paymentDate,
            growth: d.growth,
            value: getCurrencyChange({
              value: amount * d.value,
              currency: stock.currency,
              userCurrency: currency,
              config,
            }),
            type: "dividend_payout",
          });
        }
        if (
          d.date &&
          !d.paymentDate &&
          dayjs(d.recordDate).toDate() <= range &&
          dayjs(d.recordDate).toDate() >= date
        ) {
          events.push({
            title: stock.name,
            date: d.date,
            growth: d.growth,
            value: getCurrencyChange({
              value: amount * d.value,
              currency: stock.currency,
              userCurrency: currency,
              config,
            }),
            type: "dividend_payout",
          });
        }
      });
    }
  });
  return events.sort((a, b) => a.date > b.date);
};

export const getDepotData = ({
  holdings = [],
  stocks = [],
  userCurrency,
  config,
}) => {
  var allDividends = [];
  var allDividendsByMonth = [];
  var allCharts = [];
  var events = [];
  holdings.map((holding) => {
    const { amount, symbol } = holding;
    const stock = stocks.find((s) => s.symbol === symbol);

    if (stock) {
      stock["amount"] = amount;
      const stockEvents = getEvents({
        stocks: [stock],
        currency: userCurrency,
        period: "year",
        config,
      });
      events = [...events, ...stockEvents];
      const dividends = getDividends({
        dividends: stock.dividends,
        from: dayjs().subtract(12, "years").startOf("year").toDate(),
        amount: amount,
        mode: "yearly",
        to: dayjs().endOf("year").toDate(),
        currency: stock.currency,
        userCurrency,
        config,
      });
      if (allDividends.length === 0) {
        allDividends = dividends;
      } else {
        allDividends.map((item) => {
          const found = dividends.find((a) => a.label === item.label);
          if (found) {
            const val = found.value;
            item.value = parseFloat((item.value + val).toFixed(2));
            item.count = item.count + 1;
          }
        });
      }
      const dividendsByMonth = getDividends({
        dividends: stock.dividends,
        from: dayjs().startOf("year").toDate(),
        to: dayjs().endOf("year").toDate(),
        amount: amount,
        mode: "monthly",
        currency: stock.currency,
        userCurrency,
        config,
      });
      if (allDividendsByMonth.length === 0) {
        allDividendsByMonth = dividendsByMonth;
      } else {
        allDividendsByMonth.map((item) => {
          const found = dividendsByMonth.find((a) => a.label === item.label);
          if (found) {
            const val = found.value;
            item.value = parseFloat((item.value + val).toFixed(2));
            item.count = item.count + 1;
          }
        });
      }
      const chart = getChart({
        chart: stock.chart,
        from: dayjs().subtract(5, "years").startOf("year").toDate(),
        amount: amount,
        currency: stock.currency,
        userCurrency,
        config,
      });

      chart.map((item) => {
        const found = allCharts.find((a) => a.label === item.label);
        if (found) {
          const val = item.value;
          found.value = parseFloat((found.value + val).toFixed(2));
        } else {
          allCharts.push(item);
        }
      });
    }
  });

  return {
    events,
    chart: allCharts.sort((a, b) => a.date - b.date),
    dividends: allDividends,
    dividendsByMonth: allDividendsByMonth,
  };
};

export const getDepotGrowth = ({
  holdings = [],
  stocks = [],
  mode = "growth",
  price,
  currency,
  userCurrency,
  config,
}) => {
  var depotBasePrice = 0;
  var currentPrice = price || 0;
  holdings.map((holding) => {
    const stock = stocks.find((s) => s.symbol === holding.symbol);
    if (holding.amount > 0) {
      depotBasePrice = depotBasePrice + holding.amount * holding.basePrice;
    }
    if (stock && holding.amount > 0) {
      currentPrice = parseFloat(
        (
          currentPrice +
          holding.amount *
            getCurrencyChange({
              value: stock.price,
              currency,
              userCurrency,
              config,
            })
        ).toFixed(2)
      );
    }
  });
  if (mode === "growth") {
    return getPercentageChange(depotBasePrice, currentPrice);
  } else if (mode === "value") {
    return currentPrice;
  }
};

export const getChart = ({
  chart = [],
  from,
  to = dayjs().toDate(),
  amount = 1,
  mode = "pure",
  dividendReturn,
  currency,
  userCurrency,
  config,
}) => {
  if (!chart) chart = [];
  var filtered =
    from && to
      ? chart.filter(
          (d) =>
            d &&
            d.date &&
            dayjs(d.date).diff(from) >= 0 &&
            dayjs(d.date).diff(to) <= 0
        )
      : chart;
  var result = filtered.map((item) => ({
    ...item,
    value: parseFloat(
      (
        getCurrencyChange({
          value: item.value,
          currency,
          userCurrency,
          config,
        }) * amount
      ).toFixed(2)
    ),
    label: dayjs(item.date).format("MMM YYYY"),
  }));
  if (mode === "pure") {
    return result;
  } else if (mode === "value") {
    return result.reduce((acc, item) => {
      return parseFloat((acc + item.value).toFixed(2));
    }, 0);
  } else if (mode === "growth") {
    if (result.length > 2) {
      return (
        getPercentageChange(
          result[0].value,
          dividendReturn
            ? result[result.length - 1].value + dividendReturn
            : result[result.length - 1].value
        ) / 100
      );
    } else {
      return 0;
    }
  }
};

export const getDividends = ({
  dividends = [],
  from,
  to = dayjs().toDate(),
  amount = 1,
  mode = "pure",
  currency,
  userCurrency,
  config,
}) => {
  if (!dividends) dividends = [];
  var filtered =
    from && to
      ? dividends.filter(
          (d) =>
            dayjs(d.paymentDate || d.date).diff(from) >= 0 &&
            dayjs(d.paymentDate || d.date).diff(to) <= 0
        )
      : dividends;
  var result = filtered.map((item) => ({
    ...item,
    value: item.value * amount,
  }));
  if (mode === "pure") {
    return result;
  } else if (mode === "yearly") {
    const yearArry = Array.from({ length: 12 }, (_, i) => ({
      label: dayjs().subtract(11, "years").add(i, "year").format("YYYY"),
      count: 0,
      value: 0,
    }));
    return result.reduce((acc, item) => {
      const found = acc.find(
        (a) => a.label === dayjs(item.paymentDate || item.date).format("YYYY")
      );
      if (found) {
        found.value = parseFloat(
          (
            found.value +
            getCurrencyChange({
              value: item.value,
              currency,
              userCurrency,
              config,
            })
          ).toFixed(2)
        );
        found.count = found.count + 1;
      }
      return acc;
    }, yearArry);
  } else if (mode === "monthly") {
    const monthArry = Array.from({ length: 12 }, (_, i) => ({
      label: dayjs().startOf("year").add(i, "month").format("MMM"),
      count: 0,
      value: 0,
    }));
    return result.reduce((acc, item) => {
      const found = acc.find(
        (a) => a.label === dayjs(item.paymentDate || item.date).format("MMM")
      );
      if (found) {
        found.value = parseFloat(
          (
            found.value +
            getCurrencyChange({
              value: item.value,
              currency,
              userCurrency,
              config,
            })
          ).toFixed(2)
        );
        found.count = found.count + 1;
      }
      return acc;
    }, monthArry);
  } else if (mode === "value") {
    return result.reduce((acc, item) => {
      return getCurrencyChange({
        value: parseFloat((acc + item.value).toFixed(2)),
        currency,
        userCurrency,
        config,
      });
    }, 0);
  }
};

export const getDividendsByYear = ({
  dividends,
  ForwardAnnualDividendRate,
  amount,
  from,
}) => {
  if (!amount) amount = 1;
  const currentYear = new Date().getFullYear();
  const range = Array.from({ length: 12 }, (_, i) => ({
    label: dayjs()
      .subtract(11 - i, "years")
      .format("YYYY"),
    count: 0,
    value: 0,
  }));
  dividends.map((data) => {
    if ((from && from <= data.paymentDate) || !from) {
      const year = dayjs(data.paymentDate || data.date).format("YYYY");
      const found = range.find((r) => r.label === year);
      if (found) {
        const val =
          amount !== undefined
            ? parseFloat(data.value) * amount
            : parseFloat(data.value);
        found.value = parseFloat((found.value + val).toFixed("2"));
        found.count = found.count + 1;
      }
    }
  });
  return range;
};

export const getDividendsByMonth = ({
  dividends,
  ForwardAnnualDividendRate,
  amount,
  count,
}) => {
  if (!amount) amount = 1;
  const range = Array.from({ length: 12 }, (_, i) => ({
    label: dayjs().startOf("year").add(i, "month").format("MMM"),
    value: 0,
  }));
  Object.keys(dividends).map((key) => {
    const data = dividends[key];
    const year = dayjs(data.paymentDate || data.date).format("YYYY");
    const month = dayjs(data.paymentDate || data.date).format("MMM");
    const found = range.find(
      (r) => dayjs().format("YYYY") === year && r.label === month
    );
    if (found) {
      const val = parseFloat(data.value) * amount;
      found.value = parseFloat((found.value + val).toFixed("2"));
    }
  });

  return range;
};

export function getPercentageChange(oldNumber, newNumber) {
  var change = newNumber - oldNumber;
  var changeValue = 0;
  if (oldNumber >= 0) {
    changeValue = change / oldNumber;
  } else {
    changeValue = change / Math.abs(oldNumber);
  }
  if (oldNumber < newNumber) {
    changeValue = Math.abs(changeValue);
  }
  changeValue = changeValue * 100;
  return parseFloat(changeValue.toFixed(2));
}

export default {
  sort: function (arrayToSort, sortFunction) {
    if (arrayToSort.length <= 10)
      return this.insertionSort(arrayToSort, sortFunction);
    else return this.mergeSort(arrayToSort, sortFunction);
  },

  mergeSort: function (arr, sortFunction) {
    if (arr.length < 2) return arr;

    var middle = parseInt(arr.length / 2);
    var left = arr.slice(0, middle);
    var right = arr.slice(middle, arr.length);

    return this.merge(
      this.mergeSort(left, sortFunction),
      this.mergeSort(right, sortFunction),
      sortFunction
    );
  },

  merge: function (left, right, sortFunction) {
    var result = [];

    while (left.length && right.length) {
      if (sortFunction(left[0], right[0]) < 1) {
        result.push(left.shift());
      } else {
        result.push(right.shift());
      }
    }

    while (left.length) result.push(left.shift());

    while (right.length) result.push(right.shift());

    return result;
  },

  insertionSort: function (arrayToSort, sortFunction) {
    var arrLength = arrayToSort.length;
    var value;
    var i;
    var j;

    for (i = 0; i < arrLength; i++) {
      value = arrayToSort[i];
      for (j = i - 1; j > -1 && sortFunction(arrayToSort[j], value) == 1; j--) {
        arrayToSort[j + 1] = arrayToSort[j];
      }
      arrayToSort[j + 1] = value;
    }

    return arrayToSort;
  },
};

export const getSlScore = (stock) => {
  if (!stock) return {};
  const ratingGrowth = [
    {
      key: "return3Y",
      obKey: "Highlights.return3Y",
      suffix: "%",
      getPoints: (v) => {
        if (v >= 0.75) return 3;
        if (v >= 0.5) return 2;
        if (v < 0) return -3;
        if (v < 0.1) return -2;
        return 1;
      },
    },
    {
      key: "RuleOf40",
      obKey: "Financials.RuleOf40",
      suffix: "%",
      getPoints: (v) => {
        if (v >= 0.5) return 3;
        if (v >= 0.45) return 2;
        return 1;
      },
    },
    {
      key: "GrossMargin",
      obKey: "Financials.GrossMargin",
      suffix: "%",
      getPoints: (v) => {
        if (v >= 0.75) return 3;
        if (v >= 0.5) return 2;
        return 1;
      },
    },
    {
      key: "PRGRatio",
      obKey: "Financials.PRGRatio",
      getPoints: (v) => {
        if (v <= 0.5 && v >= 0) return 3;
        if (v <= 1 && v >= 0) return 2;
        return 1;
      },
    },
    {
      key: "totalRevenueGrowthTTM",
      obKey: "Financials.totalRevenueGrowthTTM",
      suffix: "%",
      getPoints: (v) => {
        if (v > 0.3) return 3;
        if (v > 0.1) return 2;
        return 1;
      },
    },
    {
      key: "totalRevenueGrowth3Y",
      obKey: "Financials.totalRevenueGrowth3Y",
      suffix: "%",
      getPoints: (v) => {
        if (v > 0.3) return 3;
        if (v > 0.1) return 2;
        return 1;
      },
    },
    {
      key: "totalRevenueGrowthYears",
      obKey: "Financials.totalRevenueGrowthYears",
      getPoints: (v) => {
        if (v >= 5) return 3;
        if (v >= 2.5) return 2;
        return 1;
      },
    },
    {
      key: "grossProfitGrowth3Y",
      obKey: "Financials.grossProfitGrowth3Y",
      suffix: "%",
      getPoints: (v) => {
        if (v > 0.15) return 3;
        if (v > 0.075) return 2;
        return 1;
      },
    },
    {
      key: "totalCashFromOperatingActivitiesGrowth3Y",
      obKey: "Financials.totalCashFromOperatingActivitiesGrowth3Y",
      suffix: "%",
      getPoints: (v) => {
        if (v > 0.1) return 3;
        if (v > 0.05) return 2;
        return 1;
      },
    },
    {
      key: "currentRatio",
      obKey: "Highlights.currentRatio",
      getPoints: (v) => {
        if (v >= 3) return 3;
        if (v >= 1.5) return 2;
        return 1;
      },
    },
    {
      key: "EnterpriseValueRevenue",
      obKey: "Valuation.EnterpriseValueRevenue",
      getPoints: (v) => {
        if (v <= 8) return 3;
        if (v <= 10) return 2;
        return 1;
      },
    },
  ];

  const ratingDividends = [
    {
      key: "PEGRatio",
      obKey: "Financials.PEGRatio",
      getPoints: (v) => {
        if (v <= 1 && v >= 0) return 3;
        if (v <= 1.5 && v >= 0) return 2;
        return 1;
      },
    },
    {
      key: "PDGRatio",
      obKey: "SplitsDividends.PDGRatio",
      getPoints: (v) => {
        if (v <= 3.5 && v >= 0) return 3;
        if (v <= 7 && v >= 0) return 2;
        return 1;
      },
    },
    {
      key: "totalRevenueGrowth3Y",
      obKey: "Financials.totalRevenueGrowth3Y",
      suffix: "%",
      getPoints: (v) => {
        if (v > 0.1) return 3;
        if (v > 0.05) return 2;
        return 1;
      },
    },
    {
      key: "netIncomeGrowth3Y",
      obKey: "Financials.netIncomeGrowth3Y",
      suffix: "%",
      getPoints: (v) => {
        if (v > 0.15) return 3;
        if (v > 0.075) return 2;
        return 1;
      },
    },
    {
      key: "totalCashFromOperatingActivitiesGrowth3Y",
      obKey: "Financials.totalCashFromOperatingActivitiesGrowth3Y",
      suffix: "%",
      getPoints: (v) => {
        if (v > 0.1) return 3;
        if (v > 0.05) return 2;
        return 1;
      },
    },
    {
      key: "GrowthTTM",
      obKey: "SplitsDividends.GrowthTTM",
      suffix: "%",
      getPoints: (v) => {
        if (v > 0.15) return 3;
        if (v > 0.075) return 2;
        return 1;
      },
    },
    {
      key: "Growth3Y",
      obKey: "SplitsDividends.Growth3Y",
      suffix: "%",
      getPoints: (v) => {
        if (v > 0.15) return 3;
        if (v > 0.075) return 2;
        return 1;
      },
    },
    {
      key: "GrowthYears",
      obKey: "SplitsDividends.GrowthYears",
      getPoints: (v) => {
        if (v >= 10) return 3;
        if (v >= 5) return 2;
        return 1;
      },
    },
    {
      key: "ForwardAnnualDividendYield",
      obKey: "SplitsDividends.ForwardAnnualDividendYield",
      suffix: "%",
      getPoints: (v) => {
        if (v >= 0.015 && v <= 0.03) return 3;
        if (v >= 0.01 && v <= 0.04) return 2;
        return 1;
      },
    },
    {
      key: "PayoutRatio",
      obKey: "SplitsDividends.PayoutRatio",
      suffix: "%",
      getPoints: (v) => {
        if (v >= 0.25 && v <= 0.75) return 3;
        if ((v > 0.75 && v <= 90) || (v >= 0.1 && v < 0.25)) return 2;
        return 1;
      },
    },
    {
      key: "ReturnOnEquityTTM",
      obKey: "Highlights.ReturnOnEquityTTM",
      suffix: "%",
      getPoints: (v) => {
        if (v >= 0.3) return 3;
        if (v >= 0.15) return 2;
        return 1;
      },
    },
    {
      key: "EnterpriseValueEbitda",
      obKey: "Valuation.EnterpriseValueEbitda",
      getPoints: (v) => {
        if (v <= 15) return 3;
        if (v <= 20) return 2;
        return 1;
      },
    },
    {
      key: "currentRatio",
      obKey: "Highlights.currentRatio",
      getPoints: (v) => {
        if (v >= 3) return 3;
        if (v >= 1.5) return 2;
        return 1;
      },
    },
  ];
  const pointsGrowth = { points: 0, max: ratingGrowth.length * 3 };
  const pointsDividend = { points: 0, max: ratingDividends.length * 3 };
  ratingGrowth.map((rate) => {
    const v = getObjValue(stock.data, rate.obKey);
    if (v !== undefined && v !== null) {
      pointsGrowth[rate.key] = rate.getPoints(v);
      pointsGrowth["points"] = pointsGrowth["points"] + rate.getPoints(v);
    }
  });
  ratingDividends.map((rate) => {
    const v = getObjValue(stock.data, rate.obKey);
    if (v !== undefined && v !== null) {
      pointsDividend[rate.key] = rate.getPoints(v);
      pointsDividend["points"] = pointsDividend["points"] + rate.getPoints(v);
    }
  });
  return { pointsGrowth, ratingGrowth, pointsDividend, ratingDividends };
};

export const sectors = [
  { name: "Healthcare", filter: { $in: ["Healthcare", "Health Care"] } },
  {
    name: "Consumer",
    filter: {
      $in: ["Consumer Goods", "Consumer Cyclical", "Consumer Defensive"],
    },
  },
  {
    name: "Technology",
    filter: {
      $in: ["Technology", "Information Technology"],
    },
  },
  {
    name: "Services",
    filter: {
      $in: ["Communication Services", "Services"],
    },
  },
  {
    name: "Financial",
    filter: { $in: ["Financial", "Financial Services"] },
  },
  {
    name: "Industrial",
    filter: { $in: ["Basic Materials", "Industrial Goods", "Industrials"] },
  },
  {
    name: "Estate/Property",
    filter: { $in: ["Real Estate", "Property"] },
  },
];
