import * as React from "react";

import { Wrapper, FinanceChart } from "../../components";

export default function App({ stock }) {
  return (
    <>
      <FinanceChart
        data={stock.data.Financials.Income_Statement}
        objkey="totalRevenue"
        label="stock.totalRevenue"
        //keyProps={{ costOfRevenue: { color: "warning" } }}
        formatValue={(v, item) =>
          `${item.growth ? `${parseInt(item.growth * 100).toFixed(0)}%` : ""}`
        }
      />
      <FinanceChart
        data={stock.data.Financials.Income_Statement}
        objkey="netIncome"
        label="stock.netIncome"
        //keyProps={{ costOfRevenue: { color: "warning" } }}
        formatValue={(v, item) =>
          `${item.growth ? `${parseInt(item.growth * 100).toFixed(0)}%` : ""}`
        }
      />
      <FinanceChart
        data={stock.data.Financials.Cash_Flow}
        objkey="totalCashFromOperatingActivities"
        label="stock.totalCashFromOperatingActivities"
        //keyProps={{ costOfRevenue: { color: "warning" } }}
        formatValue={(v, item) =>
          `${item.growth ? `${parseInt(item.growth * 100).toFixed(0)}%` : ""}`
        }
      />
      <FinanceChart
        data={stock.data.Financials.Cash_Flow}
        objkey="dividendsPaid"
        label="stock.dividendsPaid"
        //keyProps={{ costOfRevenue: { color: "warning" } }}
        formatV={(v) => Math.abs(v)}
        formatValue={(v, item) =>
          `${item.growth ? `${parseInt(item.growth * 100).toFixed(0)}%` : ""}`
        }
      />
      <FinanceChart
        data={stock.data.Financials.Balance_Sheet}
        objkey="cash"
        label="stock.cash"
        //keyProps={{ costOfRevenue: { color: "warning" } }}
        formatValue={(v, item) =>
          `${item.growth ? `${parseInt(item.growth * 100).toFixed(0)}%` : ""}`
        }
      />
      <FinanceChart
        data={stock.data.Financials.Balance_Sheet}
        objkey="longTermDebtTotal"
        label="stock.longTermDebtTotal"
        barColor="error"
        //keyProps={{ costOfRevenue: { color: "warning" } }}
        formatValue={(v, item) =>
          `${item.growth ? `${parseInt(item.growth * 100).toFixed(0)}%` : ""}`
        }
      />
    </>
  );
}
