import * as React from "react";
import { Icon, Animate, Grid, Button, Input } from "unikit";

import { useAppState } from "../../AppContext";
import ProSelect from "./ProSelect";

export default ({}) => {
  const { set, user } = useAppState();
  const [mode, setMode] = React.useState("monthly");

  return (
    <>
      <Input label="Select your Plan">
        <Grid min={100} gap={15} mt={-5} outerGap={0} row>
          <ProSelect
            mod="monthly"
            price={1.99}
            setMode={setMode}
            active={mode === "monthly"}
          />
          <ProSelect
            mod="yearly"
            price={14.99}
            setMode={setMode}
            active={mode === "yearly"}
          />
        </Grid>
      </Input>
      <Button
        size={50}
        mt={20}
        renderRight={<Icon name="arrowRight" color="#FFF" size={18} ml={6} />}
        shadow={5}
        rounded
        onPress={() => {
          set({ pro: true });
        }}
      >
        Start for free
      </Button>
    </>
  );
};
