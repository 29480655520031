import React, { Fragment } from "react";
import { useTheme, styled, isWeb, Flex, Page, P, H1 } from "unikit";
import { useSafeArea } from "react-native-safe-area-context";

import Navbar from "./Navbar";
import Lottie from "./Lottie";
import Wrapper from "./Wrapper";
import Link from "./Link";

const Spacer = styled.View();

export default ({
  children,
  hideWeb = false,
  header = true,
  loading = false,
  titleColor,
  title,
  desc,
  goBack,
  leftAction,
  rightAction,
  navComponent,
  maxWidth,
  stick,
  noSpacer = false,
  noBg = false,
  renderFooter,
  footerHeight = 0,
  scrollable = true,
  authAction = false,
  navProps = {},
  spacerProps = {},
  modal = false,
  ...rest
}) => {
  const theme = useTheme();
  const insets = useSafeArea();

  const renderContent = (childs, bottomSpacer) => {
    return (
      <Fragment>
        {noSpacer || !header || loading ? null : (
          <Spacer
            bg={noBg ? undefined : "surface"}
            w="100%"
            mt={-200}
            h={modal ? 270 : 250 + insets.top}
            {...spacerProps}
          />
        )}

        {loading ? (
          <Flex w="100%" h={theme.height} flexCenter>
            <Lottie
              height={200}
              width={200}
              animationData={require("../assets/animations/piggy_coins_out.json")}
            />
            {authAction ? <P>No User</P> : null}
          </Flex>
        ) : (
          childs
        )}
      </Fragment>
    );
  };

  return (
    <Page
      renderHeader={
        !header
          ? undefined
          : (top) => {
              return (
                <Fragment>
                  <Navbar
                    top={top}
                    title={title}
                    desc={desc}
                    goBack={goBack}
                    rightAction={rightAction}
                    leftAction={leftAction}
                    navComponent={navComponent}
                    maxWidth={maxWidth}
                    renderFooter={renderFooter}
                    titleColor={titleColor}
                    {...navProps}
                  />
                </Fragment>
              );
            }
      }
      scrollViewProps={{ scrollEventThrottle: 16 }}
      scrollable={scrollable}
      {...rest}
    >
      {children instanceof Function
        ? (scrollProps) => renderContent(children(scrollProps, false))
        : renderContent(children)}
    </Page>
  );
};
