import * as React from "react";
import { Flex, Dropdown, Button, Icon, P, Input, Form } from "unikit";
import dayjs from "dayjs";

import {
  Page,
  Wrapper,
  Search,
  ListQuery,
  StockItem,
  Link,
} from "../components";
import { useTranslation, useAppState } from "../AppContext";
import { SEARCH_QUERY } from "../Apollo";
import {
  getDividendsByYear,
  getCurrencyChange,
  getCurrencySymbol,
  getDividends,
  sectors,
  buildQuery,
} from "../stocklytics/lib/helper";

export default function Screen({ navigation: { navigate } }) {
  const { user } = useAppState();
  const [search, setSearch] = React.useState("");
  const { t } = useTranslation();

  const getRows = (item) => {
    const price = getCurrencyChange({
      value: item.price,
      currency: item.currency,
      userCurrency: user ? user.currency : item.currency,
      config: user ? user.config : undefined,
    });
    const dividends = getDividends({
      dividends: item.dividends || [],
      mode: "value",
      from: dayjs().startOf("year"),
      to: dayjs().endOf("year"),
      currency: item.currency || "USD",
      userCurrency: user ? user.config.currency : item.currency || "USD",
      config: user ? user.config : depot.config || undefined,
    });
    const diviYield = ((dividends / price) * 100).toFixed(2);
    return [
      {
        label: t("common.price"),
        value: `${price}${getCurrencySymbol(
          user ? user.currency : item.currency
        )}`,
      },
      {
        label: `${t("stock.dividends")} ${dayjs().format("YYYY")}`,
        value: `${dividends}${getCurrencySymbol(
          user ? user.currency : item.currency
        )}`,
      },
      {
        label: `${t("stock.yield")} `,
        value: `${diviYield}%`,
      },
    ];
  };

  const { filter, sort } = buildQuery({ user, sortKey: "dividendScore" });
  const scoreKey = "dividendScore";
  console.log({ filter, sort });

  return (
    <Page
      spacerProps={{ bg: "transparent" }}
      navComponent={<Search onChange={(s) => setSearch(s)} />}
    >
      <Flex w="100%" h={20} />
      {search.length > 2 ? (
        <ListQuery
          query={SEARCH_QUERY}
          name="stocks"
          limit={20}
          filter={{ search }}
          itemSize={293}
          title={t("search.results")}
          emptyDesc={"search.empty_desc"}
          noContentProps={{
            actionTitle: t("search.request_symbol"),
            action: () => {
              navigate("Settings", { support: true });
            },
          }}
          renderItem={({ item, index }) => {
            return (
              <Link to="Stock" params={{ slug: item._id }}>
                <StockItem
                  symbol={item.symbol}
                  title={item.name}
                  chart={
                    item.dividends
                      ? getDividendsByYear({ dividends: item.dividends })
                      : []
                  }
                  score={item[scoreKey]}
                  rows={getRows(item)}
                />
              </Link>
            );
          }}
        />
      ) : null}
      <Wrapper>
        <Flex
          bg="surface"
          borderTopWidth={1}
          borderRightWidth={1}
          borderLeftWidth={1}
          borderColor="text"
          borderColorAlpha={0.05}
          shadow={2}
          borderRadius={15}
          mt={15}
          p={15}
        >
          <P font="label" color="primary">
            Divilytics Score Info
          </P>
          <P mt={5}>{t("search.score_info")}</P>
        </Flex>
      </Wrapper>
      <ListQuery
        query={SEARCH_QUERY}
        name="stocks"
        limit={sort.limit}
        sort={sort.sort}
        filter={{
          type: "Common Stock",
          "data.SplitsDividends.Growth3Y": { $gte: 0.2 },
          ...filter,
        }}
        horizontal={false}
        title={t("search.score")}
        actionComp={
          <Button onPress={() => navigate("Strategy")} rounded light ssize={35}>
            Edit Strategy
          </Button>
        }
        renderItem={({ item, index }) => {
          return (
            <Link to="Stock" params={{ slug: item._id }}>
              <StockItem
                symbol={item.symbol}
                title={item.name}
                chart={
                  item.dividends
                    ? getDividendsByYear({ dividends: item.dividends })
                    : []
                }
                score={item[scoreKey]}
                rows={getRows(item)}
                full
              />
            </Link>
          );
        }}
      />
    </Page>
  );
}

{
  /* <Dropdown
            backdrop
            wrapperProps={{
              w: 275,
              r: 0,
              shadow: 60,
              t: 50,
              borderRadius: 15,
              bg: "background",
            }}
            content={({ close }) => {
              return (
                <Flex w="100%" p={10}>
                  <P font="label" color="primary" uppercase>
                    {t("search.filter")}
                  </P>
                  <Form
                    defaultDoc={filter}
                    buttonLabel={t("search.apply")}
                    buttonProps={{ rounded: true, mt: 10 }}
                    onSubmit={(doc) => {
                      setFilter(doc);
                      close();
                    }}
                  >
                    <Input
                      type="select"
                      label="Sector"
                      field="sector"
                      options={sectors.map((s) => s.name)}
                      placeholder={t("search.sector_placeholder")}
                    />
                  </Form>
                </Flex>
              );
            }}
          >
            <Button size={30} rounded light>
              <Icon name="moreHorizontal" size={20} />
            </Button>
          </Dropdown> */
}
