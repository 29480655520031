import * as React from "react";
import { useNavigation } from "@react-navigation/native";
import { AsyncStorage } from "react-native";
import { isWeb } from "unikit";

import {
  useQuery,
  USER_QUERY,
  useMutation,
  CALL_METEOR,
  HANDLE_DOC,
} from "./Apollo";

export const AppState = React.createContext();

export { useNavigation };

export * from "./AppTranslations";

function fnum(x) {
  if (isNaN(x)) return x;
  if (Math.abs(x) < 9999) {
    return x;
  }
  if (Math.abs(x) < 1000000) {
    return Math.round(x / 1000) + "K";
  }
  if (Math.abs(x) < 10000000) {
    return (x / 1000000).toFixed(2) + "M";
  }
  if (Math.abs(x) < 1000000000) {
    return (x / 1000000).toFixed(2) + "M";
  }
  if (Math.abs(x) < 1000000000000) {
    return (x / 1000000000).toFixed(2) + "B";
  }
  if (Math.abs(x) < 1000000000000000) {
    return (x / 1000000000000).toFixed(2) + "T";
  }
}

const currencySymbols = {
  USD: "$",
  EUR: "€",
};

export const AppStateProvider = ({ children }) => {
  const { data = {}, loading, error, refetch } = useQuery(USER_QUERY);
  const { user = null } = data;
  const [callMeteor, callMeteorState] = useMutation(CALL_METEOR);
  const [handleDoc, handleDocState] = useMutation(HANDLE_DOC);

  const [state, setState] = React.useState({
    user: null,
    pro: false,
  });

  const set = (obj) => {
    setState({ ...state, ...obj });
  };

  const formatPrice = ({ value, currency, mrd = false, suffix = false }) => {
    if (mrd) {
      return fnum(value);
    } else {
      if (!value) return 0;
      const val = parseFloat(
        value > 100000 ? value.toFixed(0) : value.toFixed(2)
      ).toLocaleString("de");
      if (suffix) {
        const userCurrency = user ? user.currency : undefined;
        const currencySuffix = userCurrency
          ? currencySymbols[userCurrency]
          : currencySymbols[currency];
        return val + currencySuffix;
      } else {
        return val;
      }
    }
  };

  return (
    <AppState.Provider
      value={{
        ...state,
        set,
        user,
        userLoading: loading,
        refetchUser: refetch,
        formatPrice,
        callMeteor,
        callMeteorState,
        handleDoc,
        handleDocState,
      }}
    >
      {children}
    </AppState.Provider>
  );
};

export const useAppState = () => {
  const appState = React.useContext(AppState);
  return appState;
};
