import * as React from "react";
import { AsyncStorage } from "react-native";
import { Button, useTheme, Icon } from "unikit";

import { Page, Wrapper, Form, Chat } from "../components";
import { useTranslation, useAppState } from "../AppContext";
import { useApolloClient } from "../Apollo";

export default function Screen({
  route: { params },
  navigation: { navigate },
}) {
  const [loading, setLoading] = React.useState(false);
  const { user, set } = useAppState();
  const client = useApolloClient();
  const theme = useTheme();
  const { t } = useTranslation();
  const formRef = React.useRef(null);
  const { support } = params || {};

  const roles = user ? user.roles || [] : [];

  return (
    <>
      <Page
        title={t("settings.title")}
        spacerProps={{ bg: "transparent" }}
        leftAction={
          <Button
            onPress={() => {
              AsyncStorage.removeItem("token");
              client
                .resetStore()
                .then(() => {
                  console.log("logout and reset");
                  set({ pro: false });
                })
                .catch((error) => {});
            }}
            size={30}
            rounded
            bg="surface"
          >
            <Icon name="logOut" size={18} />
          </Button>
        }
        rightAction={
          <Button
            loading={loading}
            onPress={() => formRef.current.submit()}
            size={30}
            rounded
          >
            <Icon name="check" color="#FFF" size={20} />
          </Button>
        }
      >
        <Wrapper>
          <Form
            formRef={formRef}
            _id={user._id}
            defaultDoc={user}
            type="User"
            mode="update"
            refetchQueries={["user"]}
            onLoading={(l) => setLoading(l)}
            onSuccess={(doc) =>
              theme.update({ mode: doc.darkTheme ? "dark" : "default" })
            }
            onChange={(doc) => {
              // alert(JSON.stringify(doc["notifications"]));
              if (doc.darkTheme !== undefined) {
                theme.update({ mode: doc.darkTheme ? "dark" : "default" });
              }
            }}
          />
          {roles.indexOf("admin") > -1 && (
            <Button light mt={20} onPress={() => navigate("Admin")}>
              Admin
            </Button>
          )}
        </Wrapper>
      </Page>
      <Chat label={t("common.support")} isOpen={support} />
    </>
  );
}
