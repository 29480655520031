import React, { useState } from "react";

import List from "./List";
import { useQuery } from "../Apollo";

const NoContent = ({
  query,
  filter = {},
  sort = {},
  limit,
  skip,
  name,
  ...rest
}) => {
  const [page, setPage] = useState(0);
  const [refreshing, setRefresh] = useState(false);
  const { loading, error, data, fetchMore, refetch } = useQuery(query, {
    variables: {
      filter: filter,
      sort: sort,
      page: 0,
      limit: limit || undefined,
    },
    skip: skip,
  });

  const listData = data ? data[name] : [];
  return <List data={listData} loading={loading} {...rest} />;
};

export default NoContent;
