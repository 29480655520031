import * as React from "react";
import {
  ApolloProvider as AP,
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  useMutation,
  useQuery,
  useApolloClient,
  gql,
} from "@apollo/client";
import { setContext } from "@apollo/link-context";
import { AsyncStorage } from "react-native";

export { useMutation, useQuery, useApolloClient };
const GRAPHQL_URL = __DEV__
  ? "http://192.168.2.101:5000/graphql"
  : "https://api.stocklytics.io/graphql";
const httpLink = new HttpLink({ uri: GRAPHQL_URL, credentials: "same-origin" });

const authLink = setContext(async (req, { headers }) => {
  if (typeof window === "undefined") {
    var token = null;
  } else {
    var token = await AsyncStorage.getItem("token");
  }

  return {
    ...headers,
    headers: { authorization: token ? token : null },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache({
    dataIdFromObject: (object) => object._id || null,
  }),
});

export const ApolloProvider = ({ children }) => {
  return <AP client={client}>{children}</AP>;
};

////////////////////----API----///////////////////

const DEPOT_PREVIEW = `
    _id
        currency
        name
        slug
        holdings
        chart
        public
        userId
        createdAt
        updatedAt
`;

const DEPOT_FULL = `
    ${DEPOT_PREVIEW}
        desc
        dividends
        dividendsByMonth
        events
        chart   
        pro
        strategy
        watchlist
`;

const STOCK_PREVIEW = `
  _id
  name
  slug
  chart
  symbol
  currency
  price
  growthScore
    dividendScore
    dividends
`;

const STOCK_FULL = `
    ${STOCK_PREVIEW}
    dividends
    earnings
    updatedAt
`;

export const GET_ACCESS = gql`
  mutation getAccess(
    $email: String
    $password: String!
    $mode: String
    $token: String
  ) {
    getAccess(email: $email, password: $password, mode: $mode, token: $token)
  }
`;

export const USER_QUERY = gql`
  query user {
    user {
      _id
      isPro
      stripe
      emails
      darkTheme
      pushTokens
      follows
      notifications
      score
      currency
      config
      roles
      strategy
      depots {
        ${DEPOT_FULL}
      }
      followDepots {
        ${DEPOT_FULL}
      }
    }
  }
`;

export const DEPOT_QUERY = gql`
  query depot($filter: Object) {
    depot(filter: $filter) {
      ${DEPOT_FULL}
      config
      stocks {
        ${STOCK_FULL}
      }
    }
  }
`;

export const STOCK_QUERY = gql`
  query stock($filter: Object) {
    stock(filter: $filter) {
      ${STOCK_FULL}
      data
    }
  }
`;

export const HANDLE_DOC = gql`
  mutation handleDoc($type: String!, $mode: String!, $doc: Object, $_id: ID) {
    handleDoc(type: $type, mode: $mode, doc: $doc, _id: $_id)
  }
`;

export const SEARCH_QUERY = gql`
  query stocks($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    stocks(filter: $filter, sort: $sort, page: $page, limit: $limit)
      @connection(key: "stocks", filter: ["filter"]) {
        ${STOCK_PREVIEW}
    }
  }
`;

export const CALL_METEOR = gql`
  mutation callMeteor($name: String!, $options: Object) {
    callMeteor(name: $name, options: $options)
  }
`;
