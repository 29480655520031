import React from "react";
import {
  Animate,
  Flex,
  useTheme,
  H4,
  P,
  Icon,
  Button,
  Chart,
  LineChart,
} from "unikit";

import { useTranslation } from "../AppContext";

export default ({ title, chart, desc, action, watchlist, ...rest }) => {
  const theme = useTheme();
  const isDark = theme.mode === "dark";
  const { t } = useTranslation();
  return (
    <Animate from={{ o: 0, x: 100 }} to={{ o: 1, y: 0 }}>
      <Flex
        w={250}
        h={200}
        bg="surface"
        borderTopWidth={1}
        borderRightWidth={1}
        borderLeftWidth={1}
        borderColor="text"
        borderColorAlpha={isDark ? 0.03 : 0.07}
        shadow={2}
        borderRadius={15}
        relative
        {...rest}
      >
        <Flex px={25} pt={20} minHeight={theme.isMobile ? 100 : 110}>
          <P font="label" color="primary" uppercase>
            {watchlist ? "Watchlist" : t("common.depot")}
          </P>
          <H4 fontWeight="bold" mt={3} pr={action ? 40 : 0} numberOfLines={2}>
            {title}
          </H4>
          {desc ? (
            <P mt={5} opacity={0.5} font="label">
              {desc}
            </P>
          ) : null}
        </Flex>
        {chart && chart.length > 1 && chart[chart.length - 1].value > 0 && (
          <Flex w="100%" h={100} absolute b={30} r={0}>
            <Chart
              data={chart}
              xAxis={false}
              grid={false}
              gap={3}
              barColor={"primaryLight"}
              width="100%"
              height={100}
              minBarWidth={0}
            />
          </Flex>
        )}
      </Flex>

      {action ? (
        <Button
          onPress={(e) => {
            action();
          }}
          light
          rounded
          size={30}
          absolute
          r={15}
          t={15}
        >
          <Icon name="edit" size={15} />
        </Button>
      ) : null}
    </Animate>
  );
};
