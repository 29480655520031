import * as React from "react";
import { Button, Icon } from "unikit";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import { Page, Wrapper, Form } from "../components";

export default function Screen({ route: { params }, navigation: { goBack } }) {
  const { title, onSuccess, ...rest } = params;
  const [loading, setLoading] = React.useState(false);
  const formRef = React.useRef(null);

  return (
    <Page
      title={title || ""}
      spacerProps={{ bg: "transparent" }}
      goBack={goBack}
      scrollViewComponent={KeyboardAwareScrollView}
      rightAction={
        <Button
          loading={loading}
          onPress={() => formRef.current.submit()}
          size={30}
          rounded
        >
          <Icon name="check" color="#FFF" size={20} />
        </Button>
      }
      modal
      navProps={{ modal: true }}
    >
      <Wrapper>
        <Form
          formRef={formRef}
          onLoading={(l) => setLoading(l)}
          onSuccess={(doc) => {
            if (onSuccess) {
              onSuccess(doc);
            } else {
              goBack();
            }
          }}
          {...rest}
        />
      </Wrapper>
    </Page>
  );
}
