import React, { useState, useRef, useEffect } from "react";
import { FlatList, View } from "react-native";
import {
  Progress,
  P,
  Flex,
  useTheme,
  useLayout,
  Button,
  isWeb,
  Grid,
} from "unikit";

import Wrapper from "./Wrapper";
import NoContent from "./NoContent";
import { useTranslation } from "../AppContext";

const ListView = ({
  title,
  horizontal = true,
  data = [],
  min,
  renderItem,
  renderHeader,
  renderFooter,
  style,
  loading,
  fetchMore,
  noContentText = "",
  gap = 20,
  snap = false,
  controls = true,
  itemSize,
  autoWidth = false,
  wrap = true,
  proLock = false,
  renderRight,
  offsetTop = false,
  emptyTitle = "common.empty",
  emptyDesc,
  action,
  actionTitle,
  actionComp = null,
  noContentHeight,
  noContentProps = {},
  ...rest
}) => {
  const { t } = useTranslation();
  const listRef = useRef(null);
  const [cols, setCols] = useState(0);
  const theme = useTheme();
  const { onLayout, width } = useLayout();

  const calcCols = () => {
    if (min && !horizontal) {
      if (width < 768) {
        setCols(2);
      } else {
        setCols(Math.floor(width / min));
      }
    }
  };

  useEffect(() => {
    calcCols();
  }, []);

  useEffect(() => {
    calcCols();
  }, [width]);

  const snapProps =
    snap && horizontal
      ? {
          snapToAlignment: "start",
          pagingEnabled: true,
          snapToInterval: itemSize + gap,
        }
      : {};

  const Wrap = wrap ? Wrapper : Fragment;

  const renderEmpty = () => {
    return (
      <>
        {loading || data.length > 0 ? (
          <Flex w={width || theme.width} h="100%" minHeight={120} flexCenter>
            <Progress size={30} trackWidth={3} progressWidth={3} loading />
          </Flex>
        ) : (
          <NoContent
            width={width}
            title={t(emptyTitle)}
            desc={emptyDesc ? t(emptyDesc) : undefined}
            horizontal={horizontal}
            pro={proLock}
            height={noContentHeight}
            {...noContentProps}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Wrapper zIndex={999}>
        <Flex
          pt={offsetTop ? (isWeb ? "3rem" : 40) : isWeb ? "1.5rem" : 20}
          pb={10}
          px={10}
          row
          justifyContent="space-between"
          alignItems="center"
        >
          {title ? (
            <Flex alignItems="center" row>
              <P font="h4" color="primary">
                {title}
              </P>
              {renderRight}
              {loading && data.length > 0 ? (
                <Flex ml={10}>
                  <Progress
                    size={18}
                    trackWidth={2}
                    progressWidth={3}
                    loading
                  />
                </Flex>
              ) : null}
            </Flex>
          ) : null}

          {actionComp}

          {action ? (
            <Button onPress={() => action()} size={30} rounded>
              {actionTitle}
            </Button>
          ) : null}
        </Flex>
      </Wrapper>
      <Wrap px={0}>
        {min ? (
          <Flex px={gap / 2}>
            {data && data.length > 0 ? (
              <Grid min={min} gap={gap / 2}>
                {data.map((item, index) => {
                  return renderItem({ item, index });
                })}
              </Grid>
            ) : (
              renderEmpty()
            )}
          </Flex>
        ) : (
          <FlatList
            numColumns={cols > 0 ? cols : undefined}
            onLayout={onLayout}
            ref={listRef}
            key={`flatlist-${cols ? cols.toString() : "null"}`}
            data={data}
            contentContainerStyle={{
              flex: horizontal ? 0 : 1,
              ...style,
            }}
            removeClippedSubviews={true}
            onEndReachedThreshold={0.5}
            onEndReached={() => {
              if (fetchMore && data.length >= limit && !loading) {
                fetchMore();
              }
            }}
            scrollEventThrottle={600}
            keyExtractor={(item, index) => `list-item-${index}`}
            style={{
              flexGrow: horizontal ? 0 : 1,
              flexBasis: "auto",
            }}
            renderItem={({ item, index }) => (
              <View
                style={{
                  paddingHorizontal: gap / 2,
                  width: horizontal
                    ? itemSize + gap
                    : cols > 0
                    ? `${100 / cols}%`
                    : "100%",
                  paddingBottom: 5,
                  marginBottom: horizontal ? 5 : 7,
                }}
              >
                {renderItem({ item, index })}
              </View>
            )} // item index
            ListFooterComponent={<>{renderFooter ? renderFooter() : null}</>}
            ListHeaderComponent={renderHeader || null}
            horizontal={horizontal}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            removeClippedSubviews={horizontal ? false : undefined}
            ListEmptyComponent={renderEmpty()}
            {...snapProps}
            {...rest}
          />
        )}
      </Wrap>
    </>
  );
};

export default ListView;
