import * as React from "react";
import { Form, Group, Button, Flex, Input, useTheme, Icon } from "unikit";

import { Page } from "../components";
import { useTranslation, useAppState } from "../AppContext";

import { Sectors } from "../stocklytics/lib/helper";

const SectorsInput = ({ value = [], onChange }) => {
  return (
    <Flex row wrap>
      <Button
        light={value.indexOf("all") === -1}
        onPress={() => onChange(["all"])}
        mt={7}
        mr={7}
        rounded
      >
        All
      </Button>
      {Object.keys(Sectors).map((sector, index) => {
        return (
          <Button
            key={index}
            light={value.indexOf(sector) === -1}
            onPress={() => {
              let newValue = [...value];
              if (newValue.indexOf("all") > -1) {
                newValue = newValue.filter((e) => e !== "all");
              }
              if (newValue.indexOf(sector) > -1) {
                newValue = newValue.filter((e) => e !== sector);
              } else {
                newValue.push(sector);
              }
              if (newValue.length === 0) {
                newValue = ["all"];
              }
              onChange(newValue);
            }}
            mr={7}
            mt={7}
            rounded
          >
            {sector}
          </Button>
        );
      })}
    </Flex>
  );
};

const CapInput = ({ value = [], onChange }) => {
  return (
    <Group>
      {["s", "m", "l"].map((cap, index) => {
        return (
          <Button
            key={index}
            light={value.indexOf(cap) === -1}
            onPress={() => {
              let newValue = [...value];

              if (newValue.indexOf(cap) > -1) {
                newValue = newValue.filter((e) => e !== cap);
              } else {
                newValue.push(cap);
              }

              onChange(newValue);
            }}
            ml={7}
            mt={7}
            rounded
          >
            {cap.toUpperCase()}
          </Button>
        );
      })}
    </Group>
  );
};

export default function App({ navigation: { goBack } }) {
  const theme = useTheme();
  const formRef = React.useRef(null);
  const { user, handleDoc, handleDocState } = useAppState();
  const { t } = useTranslation();

  console.log({ strategy: user.strategy });

  return (
    <Page
      title="Strategy"
      modal
      goBack={goBack}
      navProps={{ modal: true }}
      justifyContent="space-between"
      spacerProps={{ bg: "transparent" }}
      rightAction={
        <Button
          loading={handleDocState.loading}
          onPress={() => formRef.current.submit()}
          size={30}
          rounded
        >
          <Icon name="check" color="#FFF" size={20} />
        </Button>
      }
    >
      <Form
        ref={formRef}
        p={15}
        defaultDoc={user.strategy}
        button={false}
        onSubmit={(doc) => {
          handleDoc({
            variables: {
              doc: { strategy: doc },
              type: "User",
              mode: "update",
              _id: user._id,
            },
            refetchQueries: ["user"],
          })
            .then(() => {
              theme.alert({ type: "success", message: "Yeah" });
              goBack();
            })
            .catch((error) => {
              console.log({ error });
              theme.alert({
                type: "error",
                message: error.message.replace("GraphQL error: ", ""),
              });
            });
        }}
      >
        <Input label="Sectors">
          <SectorsInput field="sectors" />
        </Input>
        <Input label={t("stock.cap")}>
          <CapInput field="marketCap" />
        </Input>
        <Input
          type="range"
          label="How many Stocks?"
          min={10}
          max={50}
          steps={5}
          field="limit"
          showValue
        />
      </Form>
    </Page>
  );
}
