import * as React from "react";
import { Image, AsyncStorage } from "react-native";
import { useSafeArea } from "react-native-safe-area-context";
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from "react-native-confirmation-code-field";
import {
  Animate,
  Page,
  Progress,
  Flex,
  Button,
  styled,
  useTheme,
  H1,
  Icon,
  Input,
  Form,
  rem,
  P,
  Text,
  isWeb,
} from "unikit";

import { Wrapper, Lottie, IAP, Link } from "../components";
import { useMutation, GET_ACCESS, useApolloClient } from "../Apollo";
import { useAppState, useTranslation } from "../AppContext";

const Img = styled(Image)({});

function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

const CodeInput = ({ value, onChange, ...rest }) => {
  const theme = useTheme();
  const ref = useBlurOnFulfill({ value, cellCount: 6 });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value: value,
    setValue: onChange,
  });

  return (
    <Input {...rest}>
      <CodeField
        ref={ref}
        {...props}
        autoFocus
        value={value}
        onChangeText={(v) => {
          onChange(v);
        }}
        cellCount={6}
        rootStyle={{
          flex: 1,
          paddingBottom: isWeb ? 0 : 50,
          width: "100%",
        }}
        keyboardType="number-pad"
        renderCell={({ index, symbol, isFocused }) => (
          <Flex
            flex={1}
            h={50}
            shadow={5}
            borderWidth={1}
            borderColor={isFocused ? "primary" : "text"}
            borderColorAlpha={0.07}
            mr={index === 5 ? 0 : 5}
            borderRadius={7}
          >
            <Text
              key={index}
              style={[
                {
                  flex: 1,
                  backgroundColor: theme.colors.surface,
                  height: "100%",
                  lineHeight: isWeb ? 50 : 48,
                  fontSize: isWeb ? rem(1.2) : rem(1.5),
                  textAlign: "center",
                  borderRadius: 7,
                },
                isFocused && {
                  borderColor: theme.colors.primary,
                  borderWidth: 2,
                },
              ]}
              onLayout={getCellOnLayoutHandler(index)}
            >
              {symbol ||
                (isFocused ? <Cursor style={{ marginTop: -10 }} /> : null)}
            </Text>
          </Flex>
        )}
      />
    </Input>
  );
};

export default function App() {
  const { t } = useTranslation();
  const { user, userLoading, callMeteor, callMeteorState } = useAppState();
  const client = useApolloClient();
  const formRef = React.useRef();
  const theme = useTheme();
  const [step, setStep] = React.useState(0);
  const insets = useSafeArea();

  React.useEffect(() => {
    if (user) setStep(2);
  }, [user]);

  return (
    <Page justifyContent="space-between">
      {userLoading ? (
        <Wrapper h={theme.height / 2} pt={50 + insets.top} flexCenter>
          <Progress size={60} trackWidth={5} progressWidth={3} loading />
        </Wrapper>
      ) : (
        <Wrapper pt={50 + insets.top} maxWidth={400}>
          {step < 2 && (
            <Flex as={Animate} delay={500} w="100%" p={30} flexCenter>
              {step === 0 && (
                <Animate flexCenter>
                  <H1 textAlign="center" style={{ fontSize: rem(2.5) }}>
                    Track your
                  </H1>
                  <H1
                    textAlign="center"
                    mt={-5}
                    style={{ fontSize: rem(3.5), fontWeight: "bold" }}
                  >
                    Dividends
                  </H1>
                  <H1 textAlign="center" style={{ fontSize: rem(2.5) }}>
                    better
                  </H1>
                </Animate>
              )}

              {step === 1 && (
                <Animate flexCenter>
                  <H1 textAlign="center" style={{ fontSize: rem(2.5) }}>
                    We send a
                  </H1>
                  <H1
                    textAlign="center"
                    mt={-5}
                    style={{ fontSize: rem(3.5), fontWeight: "bold" }}
                  >
                    Login Code
                  </H1>
                  <H1 textAlign="center" style={{ fontSize: rem(2.5) }}>
                    to your E-Mail
                  </H1>
                </Animate>
              )}

              <Form
                ref={formRef}
                onChange={(doc) => {
                  if (doc && doc.password && doc.password.length === 6) {
                    formRef.current.submit();
                  }
                }}
                onSubmit={(doc) => {
                  const { email, password, password_repeat } = doc;
                  console.log(JSON.stringify(doc));
                  if (!validateEmail(email)) {
                    theme.alert({ type: "error", message: "Incorrect Mail" });
                    return;
                  }

                  if (step === 0) {
                    callMeteor({
                      variables: {
                        name: "getLoginCode",
                        options: {
                          email,
                          darkTheme: true,
                          app: "divilytics",
                        },
                      },
                    }).then((result) => {
                      console.log({ result });
                      setStep(1);
                    });
                    return;
                  }
                  if (!password || password.length === 0) {
                    theme.alert({ type: "error", message: "No Password" });
                    return;
                  }
                  callMeteor({
                    variables: {
                      name: `loginUser`,
                      options: {
                        email,
                        password,
                        darkTheme: true,
                        app: "divilytics",
                      },
                    },
                  })
                    .then((result) => {
                      console.log({ result });
                      if (result.data.getAccess === "reset") {
                        theme.alert({ type: "success", message: "Link send" });
                      } else {
                        AsyncStorage.setItem(
                          "token",
                          result.data.callMeteor.token
                        ).then(() =>
                          client
                            .resetStore()
                            .then(() => {
                              console.log("logout and reset");
                            })
                            .catch((error) => {
                              console.log(error);
                            })
                        );

                        theme.alert({ type: "success", message: "Yeah" });
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      theme.alert({ type: "error", message: error.message });
                    });
                }}
                button={false}
              >
                {step === 0 && (
                  <Input
                    type="text"
                    label="E-Mail"
                    placeholder="john@doe.com"
                    field="email"
                    keyboardType="email-address"
                    autoCapitalize="none"
                    autoCompleteType="email"
                    shadow={2}
                    borderWidth={1}
                    borderColor="text"
                    borderColorAlpha={0.05}
                  />
                )}
                {step === 1 && <CodeInput field="password" label="Code" />}
              </Form>
              <Flex row>
                {step === 1 && (
                  <Button
                    size={50}
                    mt={20}
                    shadow={5}
                    rounded
                    light
                    mr={8}
                    onPress={() => {
                      setStep(0);
                    }}
                  >
                    <Icon name="arrowLeft" size={18} />
                  </Button>
                )}
                <Button
                  size={50}
                  mt={20}
                  renderRight={
                    <Icon name="arrowRight" color="#FFF" size={18} ml={6} />
                  }
                  shadow={5}
                  rounded
                  loading={callMeteorState.loading}
                  onPress={() => {
                    formRef.current.submit();
                  }}
                >
                  {step === 0 ? t(`common.next`) : t(`common.login`)}
                </Button>
              </Flex>
            </Flex>
          )}
          {step === 2 && (
            <Flex as={Animate} delay={500} w="100%" p={30} flexCenter>
              <Animate flexCenter>
                <H1
                  textAlign="center"
                  mt={-5}
                  style={{ fontSize: rem(3.5), fontWeight: "bold" }}
                >
                  Divilytics
                </H1>
                <H1 textAlign="center" style={{ fontSize: rem(2.5) }}>
                  free for 7 days
                </H1>
              </Animate>
              <IAP />
            </Flex>
          )}
        </Wrapper>
      )}
      <Flex
        w="100%"
        h={theme.width * 0.8}
        relative
        flexCenter
        pointerEvents="none"
      >
        <Img
          source={
            theme.mode === "dark"
              ? require("../assets/images/piggy_world_dark.png")
              : require("../assets/images/piggy_world.png")
          }
          style={{
            resizeMode: "contain",
            width: theme.width,
            height: theme.height,
            position: "absolute",
          }}
        />
        <Lottie height={275} width={275} style={{ marginTop: -100 }} />
      </Flex>
      {user ? (
        <Flex w="100%" absolute b={50} l={0} r={0} row flexCenter>
          <Link
            to="Info"
            params={{
              mode: "terms",
            }}
          >
            <P px={10} color="primary">
              {t("common.terms")}
            </P>
          </Link>
          <Link
            to="Info"
            params={{
              mode: "privacy",
            }}
          >
            <P px={10} color="primary">
              {t("common.privacy")}
            </P>
          </Link>
        </Flex>
      ) : null}
    </Page>
  );
}
