import * as React from "react";
import { styled, useTheme, Flex, Dropdown, Icon, P } from "unikit";
import { Platform } from "react-native";
import { WebView } from "react-native-webview";

import { useAppState } from "../AppContext";

const Bubble = styled.View({
  minWidth: 55,
  height: 55,
  borderRadius: 30,
  backgroundColor: "primary",
});

const HTML = `<!DOCTYPE html>
<html>
  <meta charset="utf-8" />
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
  />
  <style>
    body,
    html {
      width: 100vw;
      height: 100vh;
      margin: 0;
      padding: 0;
      overflow: hidden;
    }
    * {
      box-sizing: border-box;
      text-decoration: none;
      -webkit-user-select: none; /* Safari 3.1+ */
      -moz-user-select: none; /* Firefox 2+ */
      -ms-user-select: none; /* IE 10+ */
      user-select: none; /* Standard syntax */
    }

    #chatra-wrapper {
      padding-bottom: 0px;
      background: transparent;
    }

    .container {
      padding: 20px;
    }


  </style>

  <body onload="initChatra()">
    <div
      style="position: relative; width: 100vw; height: 100vh;"
      id="chatra-wrapper"
    ></div>

    <script>
      function initChatra() {
        setTimeout(() => {
          var userId = window.userId;
          var email = window.email;

          window.ChatraSetup = {
            mode: "frame",
            injectTo: "chatra-wrapper",
            clientId: userId,
          };
          window.ChatraIntegration = {
            name: userId,
            email: email,
          };

          (function(d, w, c) {
            w.ChatraID = "Gof2cjLFm492PAQkc";
            var s = d.createElement("script");
            w[c] =
              w[c] ||
              function() {
                (w[c].q = w[c].q || []).push(arguments);
              };
            s.async = true;
            s.src = "https://call.chatra.io/chatra.js";

            if (d.head) d.head.appendChild(s);
            setTimeout(() => {
              window.reload();
            }, 1000)
           
          })(document, window, "Chatra");
        }, 1000)
      }
    </script>
  </body>
</html>
`;

export default ({ label, isOpen = false }) => {
  const { user } = useAppState();
  const theme = useTheme();
  let yourAlert = `
      window.userId = "${user._id}"
      window.email = "${user.emails[0].address}"
    `;
  const isAndroid = Platform.OS === "android";

  const initChatra = () => {
    window.ChatraSetup = {
      colors: {
        buttonText: "#FFF" /* chat button text color */,
        buttonBg: theme.colors.primary /* chat button background color */,
      },
      mode: "frame",
      injectTo: "chatra-wrapper",
      clientId: user._id,
    };
    window.ChatraIntegration = {
      email: user.emails[0].address,
    };
    (function (d, w, c) {
      w.ChatraID = "Gof2cjLFm492PAQkc";
      var s = d.createElement("script");
      w[c] =
        w[c] ||
        function () {
          (w[c].q = w[c].q || []).push(arguments);
        };
      s.async = true;
      s.src = "https://call.chatra.io/chatra.js";
      if (d.head) d.head.appendChild(s);
    })(document, window, "Chatra");
  };

  React.useEffect(() => {
    if (Platform.OS === "web") {
      initChatra();
    }
  }, []);

  if (!user) return null;

  return (
    <Flex absolute r={20} b={20}>
      <Dropdown
        backdrop
        isOpen={isOpen}
        wrapperProps={{
          w: theme.width - 20,
          h: theme.height - 250,
          r: -10,
          shadow: 3,
          borderRadius: 3,
          b: "140%",
          p: 0,
          maxWidth: 400,
        }}
        content={
          <Flex
            bg="#FFF"
            px={10}
            w="100%"
            h="100%"
            overflow="hidden"
            borderRadius={7}
          >
            {Platform.OS === "web" ? (
              <div
                style={{ position: "relative", width: "100%", height: "100%" }}
                id="chatra-wrapper"
              ></div>
            ) : (
              <WebView
                javaScriptEnabled={true}
                domStorageEnabled={true}
                originWhitelist={["*"]}
                source={{ html: HTML }}
                injectedJavaScript={yourAlert}
                style={{ flex: 1, backgroundColor: "transparent" }}
                onError={(e) => {
                  console.log({ error: e.nativeEvent });
                }}
                onLoadEnd={(e) => {
                  console.log("onLoadEnd", e);
                }}
                allowFileAccess={isAndroid}
                mixedContentMode={isAndroid ? "always" : undefined}
                originWhitelist={["*"]}
              />
            )}
          </Flex>
        }
      >
        <Bubble shadow={60} flexCenter px={15} row>
          <Icon name="messageCircle" color="#FFF" size={label ? 25 : 30} />
          {label ? (
            <P px={10} style={{ lineHeight: 15 }} color="#FFF">
              {label}
            </P>
          ) : null}
        </Bubble>
      </Dropdown>
    </Flex>
  );
};
