import SimpleSchema from "./simple";

const simpleSchema = new SimpleSchema({
  username: {
    type: String,
    regEx: /^[a-z0-9A-Z_]{3,15}$/,
    optional: true,
  },
  emails: {
    type: Array,
    form: true,
    component: "text",
    formKey: "emails.0.address",
  },
  "emails.$": {
    type: Object,
  },
  "emails.$.address": {
    type: String,
    regEx: SimpleSchema.RegEx.Email,
  },
  "emails.$.verified": {
    type: Boolean,
    optional: true,
  },
  isPro: {
    type: Boolean,
    optional: true,
  },
  createdAt: {
    type: Date,
    label: "Created At",
    optional: true,
  },
  lastLogin: {
    type: Date,
    label: "lastLogin",
    optional: true,
  },
  notifications: {
    type: Object,
    optional: true,
    blackbox: true,
    form: true,
    component: "multiswitch",
    options: ({ user, t }) => [
      { label: t("form.dividend_payout"), value: "dividend_payout" },
      { label: t("form.dividend_ex"), value: "dividend_ex" },
      { label: t("form.earnings"), value: "earnings" },
    ],
  },
  score: {
    type: Object,
    optional: true,
    blackbox: true,
    form: true,
    component: "multiswitch",
    options: ({ user, t }) => [
      { label: t("form.rebalancing"), value: "rebalancing" },
    ],
  },
  currency: {
    type: String,
    optional: true,
    form: true,
    component: "tabs",
    options: ["EUR", "USD"],
  },
  darkTheme: {
    type: Boolean,
    optional: true,
    form: true,
    component: "tabs",
    options: ({ user, t }) => [
      { label: t("form.light"), value: false },
      { label: t("form.dark"), value: true },
    ],
  },
  follows: {
    type: Array,
    optional: true,
    defaultValue: [],
  },
  "follows.$": {
    type: String,
    optional: true,
  },
  pushTokens: {
    type: Array,
    optional: true,
  },
  "pushTokens.$": {
    type: String,
    optional: true,
  },
  services: {
    type: Object,
    optional: true,
    blackbox: true,
  },
  stripe: {
    type: Object,
    optional: true,
    blackbox: true,
  },
  debitoor_id: {
    type: String,
    optional: true,
  },
  roles: {
    type: Array,
    optional: true,
  },
  "roles.$": {
    type: String,
    optional: true,
  },
});

if (typeof Mongo !== "undefined") {
  Meteor.users.attachSchema(simpleSchema);
}

export default simpleSchema;
