import * as React from "react";
import { Animate, Button, Icon } from "unikit";
import dayjs from "dayjs";

import {
  Page,
  List,
  DepotOverview,
  StockItem,
  NoContent,
  Link,
} from "../components";
import { useTranslation, useAppState } from "../AppContext";
import { useQuery, DEPOT_QUERY } from "../Apollo";
import {
  getDividendsByYear,
  getDepotGrowth,
  getCurrencySymbol,
  getDividends,
} from "../stocklytics/lib/helper";

export default function App({
  route: { params },
  navigation: { navigate, goBack },
}) {
  const { user } = useAppState();
  const { slug } = params;
  const { data = {}, loading, error } = useQuery(DEPOT_QUERY, {
    variables: {
      filter: {
        $or: [{ _id: slug }, { slug: slug }],
      },
    },
  });
  const { depot = {} } = data;
  const { t } = useTranslation();

  if (loading || !depot) {
    return <Page loading></Page>;
  }

  return (
    <Page
      title={depot.name}
      goBack={goBack}
      rightAction={
        <Button
          onPress={() =>
            navigate("Form", {
              _id: depot._id,
              title: `${depot.name}`,
              defaultDoc: depot,
              type: "Depot",
              mode: "update",
              refetchQueries: ["user"],
              remove: true,
            })
          }
          size={30}
          bg="text"
          light
          rounded
        >
          <Icon name="edit" color="#FFF" size={20} />
        </Button>
      }
      titleColor="#FFF"
      spacerProps={{ bg: "primary" }}
    >
      <DepotOverview
        dividends={depot.dividends || []}
        dividendsByMonth={depot.dividendsByMonth || []}
        chart={depot.chart || []}
      />

      {depot.stocks.length === 0 ? (
        <NoContent
          title={t("depot.empty")}
          desc={t("depot.empty_desc")}
          action={() => {
            navigate("Search");
          }}
          mt={-30}
        />
      ) : (
        <List
          min={300}
          horizontal={false}
          data={depot.stocks}
          title={t("depot.holdings")}
          action={() =>
            navigate("Form", {
              _id: depot._id,
              title: `${depot.name} Holdings`,
              defaultDoc: { holdings: depot.holdings },
              type: "Depot",
              mode: "update",
              fields: ["holdings"],
              refetchQueries: (doc) => [
                "user",
                {
                  query: DEPOT_QUERY,
                  variables: {
                    filter: { _id: depot._id },
                  },
                },
              ],
            })
          }
          actionTitle={<Icon name="edit" color="#FFF" size={18} />}
          renderItem={({ item, index }) => {
            const holding = depot.holdings.find(
              (h) => h && h.symbol === item.symbol
            );

            const currencySymbol = getCurrencySymbol(
              user ? user.currency : depot.currency
            );
            if (!holding) return null;
            return (
              <Link to="Stock" params={{ slug: item._id }}>
                <StockItem
                  symbol={item.symbol}
                  title={item.name}
                  chart={
                    item.dividends
                      ? getDividendsByYear({ dividends: item.dividends || [] })
                      : []
                  }
                  score={item.dividendScore || 0}
                  rows={[
                    {
                      label: `Wert`,
                      value: `${getDepotGrowth({
                        holdings: [holding],
                        stocks: [item],
                        mode: "value",
                        currency: item.currency || "USD",
                        userCurrency: user
                          ? user.currency
                          : depot.currency || "USD",
                        config: user ? user.config : undefined,
                      })}${currencySymbol}`,
                    },

                    {
                      label: `${t("stock.dividends")} ${dayjs().format(
                        "YYYY"
                      )}`,
                      value: `${getDividends({
                        dividends: item.dividends || [],
                        mode: "value",
                        from: dayjs().startOf("year"),
                        amount: holding.amount,
                        currency: item.currency || "USD",
                        userCurrency: user
                          ? user.currency
                          : item.currency || "USD",
                        config: user ? user.config : undefined,
                      })}/${getDividends({
                        dividends: item.dividends || [],
                        mode: "value",
                        from: dayjs().startOf("year"),
                        to: dayjs().endOf("year"),
                        amount: holding.amount,
                        currency: item.currency || "USD",
                        userCurrency: user
                          ? user.config.currency
                          : item.currency || "USD",
                        config: user ? user.config : depot.config || undefined,
                      })}${currencySymbol}`,
                    },
                    {
                      label: t("stock.amount"),
                      value: `${holding.amount || 0}x`,
                      right: true,
                    },
                  ]}
                  full
                />
              </Link>
            );
          }}
        />
      )}
    </Page>
  );
}
