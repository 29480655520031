import "react-native-gesture-handler";
import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { ThemeProvider, color, isWeb } from "unikit";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { ApolloProvider } from "./Apollo";

import * as Haptics from "expo-haptics";

import { AppStateProvider, useAppState } from "./AppContext";
import AppNavigation from "./AppNavigation";
console.disableYellowBox = true;

function App() {
  const { user } = useAppState();

  // console.log({ user });

  return (
    <SafeAreaProvider>
      <ThemeProvider
        mode={user ? (user.darkTheme ? "dark" : "default") : "dark"}
        onFeedback={(type) => {
          if (!isWeb) Haptics.notificationAsync(type);
        }}
        theme={{
          colors: {
            background: "#F9F8F2",
            primary: "#54C1BE",
            shadow: "#E6E3E3",
            primaryLight: color("#54C1BE").setAlpha(0.1).toRgbString(),
            primaryMedium: color("#54C1BE").setAlpha(0.5).toRgbString(),
            text: "#5A6175",
            success: "#54C1BE",
            error: "#F44375",
            border: "rgba(0,0,0,0.1)",
            modes: {
              dark: {
                background: "#211E39",
                surface: "#34345D",
                input: "#34345D",
                shadow: "rgba(0,0,0,0.3)",
                text: "#FFF",
                border: "rgba(255,255,255,0.1)",
              },
            },
          },
        }}
      >
        <AppNavigation />
      </ThemeProvider>
    </SafeAreaProvider>
  );
}

export default function ApolloApp() {
  const linking = {
    prefixes: ["https://localhost:19006", "divilytics://"],
    config: {
      screens: {
        Depot: "depot/:id",
        Calendar: "calendar",
      },
    },
  };

  return (
    <NavigationContainer>
      <ApolloProvider>
        <AppStateProvider>
          <App />
        </AppStateProvider>
      </ApolloProvider>
    </NavigationContainer>
  );
}
