import SimpleSchema from "./simple";

const simpleSchema = new SimpleSchema({
  name: {
    type: String,
    form: true,
  },
  slug: {
    type: String,
    optional: true,
  },
  currency: {
    type: String,
    options: [
      { value: "EUR", label: "EUR" },
      { value: "USD", label: "USD" },
    ],
  },
  growth: {
    type: Number,
    optional: true,
  },
  strategy: {
    type: String,
    optional: true,
  },
  holdings: {
    type: Array,
    autoValue: function () {
      if ((this.isInsert && !this.isSet) || (this.isUpsert && !this.isSet)) {
        return [];
      }
    },
    optional: true,
    component: "Holdings",
  },
  "holdings.$": {
    type: Object,
    optional: true,
    blackbox: true,
  },
  chart: {
    type: Array,
    optional: true,
    autoValue: function () {
      if (this.isInsert || this.isUpsert) {
        return [];
      }
    },
  },
  "chart.$": {
    type: Object,
    optional: true,
    blackbox: true,
  },
  dividends: {
    type: Array,
    optional: true,
    autoValue: function () {
      if (this.isInsert || this.isUpsert) {
        return [];
      }
    },
  },
  "dividends.$": {
    type: Object,
    optional: true,
    blackbox: true,
  },
  dividendsByMonth: {
    type: Array,
    optional: true,
    autoValue: function () {
      if (this.isInsert || this.isUpsert) {
        return [];
      }
    },
  },
  "dividendsByMonth.$": {
    type: Object,
    optional: true,
    blackbox: true,
  },
  events: {
    type: Array,
    optional: true,
    autoValue: function () {
      if (this.isInsert || this.isUpsert) {
        return [];
      }
    },
  },
  "events.$": {
    type: Object,
    optional: true,
    blackbox: true,
  },
  userId: {
    type: String,
    optional: true,
  },
  public: {
    type: Boolean,
    optional: true,
    form: false,
    component: "switch",
  },
  watchlist: {
    type: Boolean,
    optional: true,
    form: true,
    component: "switch",
  },
  pro: {
    type: Boolean,
    optional: true,
  },
  rebalance: {
    type: Boolean,
    optional: true,
  },
  updatedAt: {
    type: Date,
    optional: true,
  },
  createdAt: {
    type: Date,
    autoValue: function () {
      if (this.isInsert) {
        return new Date();
      } else if (this.isUpsert) {
        return { $setOnInsert: new Date() };
      } else {
        this.unset(); // Prevent user from supplying their own value
      }
    },
  },
});

if (typeof Mongo !== "undefined") {
  Depot = new Mongo.Collection("depot");
  Depot.schema = simpleSchema;
  Depot.attachSchema(simpleSchema);
  Depot._ensureIndex({ slug: 1 });
}

export default simpleSchema;
