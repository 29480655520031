import * as React from "react";

import { Lottie } from "@alfonmga/react-lottie-light-ts";

export default ({
  animationData,
  height = 175,
  width = 175,
  options = {},
  ...rest
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData:
      animationData || require("../../assets/animations/piggy_hi.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    ...options,
  };

  return (
    <Lottie config={defaultOptions} height={height} width={width} {...rest} />
  );
};
