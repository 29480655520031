import SimpleSchema from "simpl-schema";
SimpleSchema.extendOptions([
  "desc",
  "form",
  "component",
  "options",
  "group",
  "formKey",
  "disabled",
]);

export default SimpleSchema;
