import * as React from "react";
import { styled, Animate, Headline, Button } from "unikit";

import Lottie from "../Lottie";
import { useTranslation } from "../../AppContext";

const ProSelect = styled.TouchableOpacity(({ active }) => ({
  width: "100%",
  height: "auto",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "surface",
  borderRadius: 7,
  borderWidth: 2,
  borderColor: active ? "primary" : "surface",
  padding: 15,
}));

export default ({ mod, price, active, setMode }) => {
  const { t } = useTranslation();
  return (
    <Animate delay={mod === "monthly" ? 150 : 300}>
      <ProSelect onPress={() => setMode(mod)} active={active} shadow={2}>
        <Headline level={4}>{`${t(`pro.${mod}`)}`}</Headline>
        <Button size={38} mt={10} rounded>{`€${price}`}</Button>
        {active ? (
          <Lottie
            animationData={require("../../assets/animations/confetti.json")}
            height={150}
            width={150}
            options={{ loop: false }}
            style={{
              position: "absolute",
              top: -20,
              left: "50%",
              marginLeft: -60,
              zIndex: 0,
            }}
          />
        ) : null}
      </ProSelect>
    </Animate>
  );
};
