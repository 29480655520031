import * as React from "react";
import { Platform, StatusBar } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  createStackNavigator,
  TransitionPresets,
} from "@react-navigation/stack";

import { useTheme, Icon, isWeb } from "unikit";

import {
  Auth,
  Depots,
  Settings,
  Search,
  Calendar,
  Form,
  Depot,
  Stock,
  Info,
  Admin,
  Strategy,
  Landing,
} from "./screens";
import { useAppState } from "./AppContext";
import { Push, Updates } from "./components";

const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();
const RootStack = createStackNavigator();

const screenOptions = {
  headerShown: false,
  safeAreaInset: "never",
};

const modalOptions = {
  ...TransitionPresets.ModalPresentationIOS,
  gestureEnabled: true,
  cardOverlayEnabled: true,
  animationEnabled: true,
  cardStyle: {
    backgroundColor: "transparent",
  },
};

const screens = [
  <Stack.Screen
    name="Depot"
    component={Depot}
    options={{
      ...screenOptions,
    }}
  />,
  <Stack.Screen
    name="Stock"
    component={Stock}
    options={{
      ...screenOptions,
    }}
  />,
];

function DepotsStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Depots"
        component={Depots}
        options={{
          ...screenOptions,
        }}
      />
      {screens}
    </Stack.Navigator>
  );
}

function SearchStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Search"
        component={Search}
        options={{
          ...screenOptions,
        }}
      />
      {screens}
    </Stack.Navigator>
  );
}

function CalendarStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Calendar"
        component={Calendar}
        options={{
          ...screenOptions,
        }}
      />
      {screens}
    </Stack.Navigator>
  );
}

function SettingsStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Settings"
        component={Settings}
        options={{
          ...screenOptions,
        }}
      />
      <Stack.Screen
        name="Admin"
        component={Admin}
        options={{
          ...screenOptions,
        }}
      />
    </Stack.Navigator>
  );
}

function Authstack() {
  return (
    <Stack.Navigator>
      {isWeb && (
        <Stack.Screen
          name="Landing"
          component={Landing}
          options={{
            ...screenOptions,
          }}
        />
      )}
      <Stack.Screen name="Auth" component={Auth} options={screenOptions} />
      <Stack.Screen name="Info" component={Info} options={screenOptions} />
    </Stack.Navigator>
  );
}

export default function AppNavigation() {
  const theme = useTheme();
  const { user, userLoading, pro, handleDoc } = useAppState();

  const renderIcon = ({ name, focused, iconSize }) => {
    return (
      <Icon
        color={focused ? "primary" : "primaryMedium"}
        name={name}
        size={iconSize || 28}
        strokeWidth={focused ? 1.75 : 1}
      />
    );
  };

  return (
    <>
      {Platform.OS === "ios" && (
        <StatusBar
          barStyle={theme.mode === "dark" ? "light-content" : "dark-content"}
        />
      )}
      <RootStack.Navigator
        name="Root"
        screenOptions={{ animationEnabled: false }}
        mode="modal"
      >
        {user && pro ? (
          <RootStack.Screen
            name="Main"
            options={{
              ...screenOptions,
            }}
          >
            {() => (
              <Tab.Navigator
                initialRouteName="Depots"
                tabBarOptions={{
                  showLabel: false,
                  style: {
                    backgroundColor: theme.colors.background,
                    borderTopColor: theme.colors.border,
                  },
                }}
              >
                <Tab.Screen
                  name="Depots"
                  component={DepotsStack}
                  options={{
                    title: "Get Started",
                    tabBarIcon: ({ ...rest }) =>
                      renderIcon({ name: "barChart", ...rest }),
                  }}
                />
                <Tab.Screen
                  name="Calendar"
                  component={CalendarStack}
                  options={{
                    title: "Calendar",
                    tabBarIcon: ({ ...rest }) =>
                      renderIcon({ name: "calendar", ...rest }),
                  }}
                />
                <Tab.Screen
                  name="Search"
                  component={SearchStack}
                  options={{
                    title: "Search",
                    tabBarIcon: ({ ...rest }) =>
                      renderIcon({ name: "search", ...rest }),
                  }}
                />
                <Tab.Screen
                  name="Settings"
                  component={SettingsStack}
                  options={{
                    title: "Settings",
                    tabBarIcon: ({ ...rest }) =>
                      renderIcon({ name: "settings", iconSize: 26, ...rest }),
                  }}
                />
              </Tab.Navigator>
            )}
          </RootStack.Screen>
        ) : (
          <>
            <RootStack.Screen
              name="Auth"
              component={Authstack}
              options={{
                ...screenOptions,
              }}
            />
          </>
        )}

        <RootStack.Screen
          name="Form"
          component={Form}
          options={{
            ...screenOptions,
            ...modalOptions,
          }}
        />
        <RootStack.Screen
          name="Strategy"
          component={Strategy}
          options={{
            ...screenOptions,
            ...modalOptions,
          }}
        />
      </RootStack.Navigator>

      {user && pro ? (
        <Push
          onToken={(token) => {
            console.log({ token });
            const pushTokens = user.pushTokens ? [...user.pushTokens] : [];
            if (pushTokens.indexOf(token) === -1 && !userLoading) {
              pushTokens.push(token);
              console.log({ pushTokens });

              handleDoc({
                variables: {
                  type: "User",
                  mode: "update",
                  doc: { pushTokens },
                  _id: user._id,
                },
                refetchQueries: ["user"],
              });
            }
          }}
        />
      ) : null}
      <Updates />
    </>
  );
}
