import * as React from "react";
import { TouchableOpacity } from "react-native";
import { useTheme, Input, Flex, Text, Icon } from "unikit";

import { cleanName } from "../stocklytics/lib/helper";

const Holdings = ({ value = [], onChange }) => {
  const theme = useTheme();
  return (
    <Flex>
      <Text mt={15} fontSize="label">
        Holdings
      </Text>
      {value.map((holding, index) => {
        return (
          <Flex row jc="flex-start" mt={10} key={holding.symbol}>
            <Flex w="50%" pr={10} relative>
              <Input
                type="text"
                shadow={50}
                value={holding.name ? cleanName(holding.name) : holding.symbol}
                editable={false}
                style={{
                  width: "100%",
                  borderRadius: 15,
                }}
              />
              <Flex
                p={theme.globals.inputGap / 2}
                h="100%"
                t={0}
                r={10}
                absolute
              >
                <Flex
                  bg="background"
                  row
                  h="100%"
                  style={{
                    borderRadius: theme.globals.roundness,
                    zIndex: 10,
                  }}
                >
                  <Flex
                    as={TouchableOpacity}
                    onPress={() => {
                      var newValue = value.filter(
                        (v) => v.symbol !== holding.symbol
                      );
                      onChange(newValue);
                    }}
                    w={38}
                    h="100%"
                    align="center"
                    content="center"
                  >
                    <Icon name="trash" size={18} color="primary" />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Input
              type="number"
              shadow={50}
              value={holding.amount}
              defaultValue={0}
              style={{
                width: "50%",
                borderRadius: 15,
              }}
              min={0}
              autoFocus={index === 0}
              onChange={(amount) => {
                var newValue = value;
                newValue.find(
                  (v) => v.symbol === holding.symbol
                ).amount = amount;
                onChange(newValue);
              }}
            />
          </Flex>
        );
      })}
    </Flex>
  );
};

export default Holdings;
