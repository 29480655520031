import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Flex, Input, Icon, useDebounce, useUpdateEffect } from "unikit";

import { useTranslation } from "../AppContext";

export default forwardRef(({ onChange, ...rest }, ref) => {
  const [string, setString] = useState("");
  const { t } = useTranslation();

  const debouncedString = useDebounce(string, 600);

  useUpdateEffect(() => {
    if (onChange) onChange(debouncedString);
  }, [debouncedString]);

  useImperativeHandle(ref, () => ({
    reset: () => {
      setString("");
    },
  }));

  return (
    <Flex
      bg="surface"
      flex={1}
      row
      h={50}
      shadow={2}
      borderRadius={25}
      {...rest}
    >
      <Input.Text
        value={string}
        placeholder={t("search.placeholder")}
        h="100%"
        pl={50}
        onChange={(text) => setString(text)}
        autoFocus={false}
      />
      <Icon
        name="search"
        size={25}
        absolute
        l={14}
        t={12}
        pointerEvents="none"
      />
      {string.length > 0 ? (
        <Icon
          name="x"
          size={25}
          absolute
          r={14}
          t={12}
          onPress={() => {
            setString("");
          }}
        />
      ) : null}
    </Flex>
  );
});
