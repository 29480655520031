import * as React from "react";
import { TouchableWithoutFeedback, Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";

export default ({ children, to, params = {}, style, ...rest }) => {
  const { navigate } = useNavigation();
  return (
    <TouchableWithoutFeedback
      onPress={() => {
        navigate(to, params);
      }}
      style={{
        ...style,
        ...Platform.select({
          web: {
            cursor: "pointer",
          },
        }),
      }}
      {...rest}
    >
      {children}
    </TouchableWithoutFeedback>
  );
};
