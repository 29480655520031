import React, { useState, useEffect, Fragment } from "react";
import { Alert } from "react-native";

import { useTheme, Form, Input, Button, Icon, isWeb } from "unikit";

import { useMutation, HANDLE_DOC } from "../Apollo";
import { useTranslation, useAppState } from "../AppContext";
import { DepotSchema, UserSchema } from "../stocklytics/lib/schemas";
import Holdings from "./Holdings";

const Components = {
  Holdings,
};

const schemas = {
  User: UserSchema,
  Depot: DepotSchema,
};

function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}

export default ({
  formRef,
  type,
  _id,
  defaultDoc,
  remove = false,
  mode,
  title,
  fields,
  refetchQueries,
  onLoading,
  onSuccess,
  ...rest
}) => {
  const { user } = useAppState();
  const theme = useTheme();
  const [schema, setSchema] = useState(() => schemas[type]);
  const [handleDoc, { loading }] = useMutation(HANDLE_DOC);
  const { t } = useTranslation();

  useEffect(() => {
    if (type && schemas[type]) {
      setSchema(schemas[type]);
    }
  }, [type]);

  useEffect(() => {
    setTimeout(
      () => {
        if (onLoading) onLoading(loading);
      },
      loading ? 0 : 1500
    );
  }, [loading]);

  const deleteDoc = () => {
    handleDoc({
      variables: {
        type: type,
        mode: "delete",
        _id: _id,
      },
      refetchQueries: isFunction(refetchQueries)
        ? refetchQueries(defaultDoc)
        : refetchQueries,
    })
      .then(() => {
        theme.alert({ type: "success", message: "Yeah" });
        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        console.log({ error });
        theme.alert({
          type: "error",
          message: error.message.replace("GraphQL error: ", ""),
        });
      });
  };

  return (
    <Fragment>
      <Form
        defaultDoc={{ ...defaultDoc }}
        ref={formRef}
        button={false}
        onSubmit={(doc) => {
          if (loading) return false;
          const cleanedDoc = schema.clean(doc);
          if (cleanedDoc["roles"]) {
            delete cleanedDoc["roles"];
          }
          if (cleanedDoc["pushTokens"]) {
            delete cleanedDoc["pushTokens"];
          }

          handleDoc({
            variables: {
              doc: cleanedDoc,
              type: type,
              mode: mode,
              _id: _id,
            },
            refetchQueries: isFunction(refetchQueries)
              ? refetchQueries(Object.assign({}, defaultDoc, doc))
              : refetchQueries,
          })
            .then(() => {
              theme.alert({ type: "success", message: "Yeah" });
              if (onSuccess) onSuccess(cleanedDoc);
            })
            .catch((error) => {
              console.log({ error });
              theme.alert({
                type: "error",
                message: error.message.replace("GraphQL error: ", ""),
              });
            });
        }}
        {...rest}
      >
        {schema &&
          Object.keys(schema._schema).map((key) => {
            var {
              component = "text",
              options,
              label,
              form,
              formKey,
              type,
              disabled = false,
              ...rest
            } = schema._schema[key];

            if (
              (form === true && !fields) ||
              (fields && fields.indexOf(key) > -1)
            ) {
              var optionsWithState = undefined;
              if (isFunction(options)) {
                optionsWithState = options({ user, t });
              }
              if (disabled && isFunction(disabled)) {
                disabled = disabled({ user, t });
              }
              if (Components[component]) {
                const Comp = Components[component];
                return <Comp field={key} key={key} />;
              }

              return (
                <Input
                  type={component}
                  key={key}
                  field={formKey || key}
                  label={t(`form.${key}`, {
                    symbol: user.config.symbol,
                  })}
                  shadow={3}
                  defaultValue={defaultDoc ? defaultDoc[key] : undefined}
                  placeholder={t(`form.${key}`, {
                    symbol: user.config.symbol,
                  })}
                  style={{
                    width: "100%",
                    borderRadius: 15,
                  }}
                  disabled={disabled}
                  options={optionsWithState || options}
                  {...rest}
                />
              );
            }
          })}
      </Form>
      {remove ? (
        <Button
          onPress={() => {
            if (isWeb) {
              var r = confirm(t("common.sure_question"));
              if (r == true) {
                deleteDoc();
              } else {
                console.log("Cancel Pressed");
              }
            } else {
              Alert.alert(
                t("common.sure_question"),
                "",
                [
                  {
                    text: t("common.cancel"),
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel",
                  },
                  {
                    text: t("common.yes"),
                    onPress: () => deleteDoc(),
                  },
                ],
                { cancelable: false }
              );
            }
          }}
          w="100%"
          bg="error"
          mt={7}
          shadow={2}
          light
        >
          <Icon name="trash" size={25} color="error" />
        </Button>
      ) : null}
    </Fragment>
  );
};
