import * as React from "react";
import { AsyncStorage } from "react-native";
import { Flex, H5, Group, Button } from "unikit";

import { Page, Wrapper, Search, List } from "../components";
import { useTranslation, useAppState } from "../AppContext";

export default function Screen({ route: { params }, navigation: { goBack } }) {
  const [loading, setLoading] = React.useState(false);
  const { user, callMeteor } = useAppState();
  const [results, setResults] = React.useState(null);

  return (
    <>
      <Page title={"Admin"} goBack={goBack} spacerProps={{ bg: "transparent" }}>
        <Wrapper mt={20}>
          <Search
            onChange={(string) => {
              callMeteor({
                variables: { name: "getStockAdmin", options: { string } },
              }).then((result) => {
                console.log({ result });
                setResults(result.data.callMeteor.search);
              });
            }}
          />
          {results && results.length > 0 && (
            <List
              data={results}
              itemSize={293}
              title="Results"
              emptyDesc={"search.empty_desc"}
              renderItem={({ item, index }) => {
                return (
                  <Flex bg="surface" w={293} p={20}>
                    <H5>{`Name: ${item.Name}`}</H5>
                    <H5>{`Symbol: ${item.Code}.${item.Exchange}`}</H5>
                    <H5>{`Currency: ${item.Currency}`}</H5>
                    <Group>
                      <Button
                        onPress={() => {
                          callMeteor({
                            variables: {
                              name: "getStock",
                              options: {
                                symbol: `${item.Code}.${item.Exchange}`,
                                name: item.Name,
                              },
                            },
                          })
                            .then((result) => {
                              console.log({ result });
                              alert("success");
                            })
                            .catch((error) => {
                              alert(error.message);
                            });
                        }}
                      >
                        Add
                      </Button>
                    </Group>
                  </Flex>
                );
              }}
            />
          )}
        </Wrapper>
      </Page>
    </>
  );
}
