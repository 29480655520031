import * as React from "react";
import { useSafeArea } from "react-native-safe-area-context";

import {
  Animate,
  Page,
  Flex,
  useTheme,
  H1,
  rem,
  P,
  Button,
  Icon,
} from "unikit";

import { Wrapper, Lottie, Link } from "../components";
import { useTranslation } from "../AppContext";

export default function App({ navigation: { navigate } }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const insets = useSafeArea();

  return (
    <Page scrollable={false} bg="surface" overflow="hidden">
      <Flex
        h={65}
        r={20}
        zIndex={9999}
        row
        alignItems="center"
        absolute
        l={20}
        t={10}
        row
        justifyContent="space-between"
      >
        <Link to="Auth">
          <Flex flexCenter row>
            <Flex w={60} h={60} shadow={3} borderRadius={15} overflow="hidden">
              <img
                src={require("../assets/images/icon.png")}
                style={{ width: "100%", height: "100%" }}
              />
            </Flex>
            <H1 font="h5" ml={12}>
              Divilytics
            </H1>
          </Flex>
        </Link>
        {__DEV__ && (
          <Button
            size={50}
            mt={20}
            renderRight={
              <Icon name="arrowRight" color="#FFF" size={18} ml={6} />
            }
            shadow={5}
            rounded
            onPress={() => {
              navigate("Auth");
            }}
          >
            {t(`common.login`)}
          </Button>
        )}
      </Flex>
      <Wrapper maxWidth={400}>
        <Flex as={Animate} delay={500} w="100%" h="100%" p={30} flexCenter>
          <Flex relative>
            <img
              src={require("../assets/images/phones.png")}
              style={{
                width: "90vw",
                maxWidth: 1100,
                minWidth: 800,
                marginTop: 20,
              }}
            />
            <Flex
              absolute
              l={0}
              r={0}
              b="10%"
              h="80%"
              alignItems="center"
              justifyContent="space-around"
            >
              <Animate mt={75} flexCenter>
                <H1 textAlign="center" style={{ fontSize: rem(2) }}>
                  Track your
                </H1>
                <H1
                  textAlign="center"
                  mt={-5}
                  style={{ fontSize: rem(3), fontWeight: "bold" }}
                >
                  Dividends
                </H1>
                <H1 textAlign="center" mb={30} style={{ fontSize: rem(2) }}>
                  better
                </H1>
                <a href="https://apps.apple.com/us/app/divilytics/id1507353361">
                  <img
                    src={require("../assets/images/appstore.svg")}
                    style={{ width: "170px", height: "auto" }}
                  />
                </a>
              </Animate>
              <Lottie height={275} width={275} />
            </Flex>
          </Flex>
        </Flex>
      </Wrapper>
      <Flex
        flexCenter
        h={44}
        bg="background"
        zIndex={9999}
        row
        alignItems="center"
        borderRadius={22}
        absolute
        l={20}
        b={20}
        shadow={3}
        px={15}
      >
        <Link
          to="Info"
          params={{
            mode: "imprint",
          }}
        >
          <P font="label" px={10} color="primary">
            {t("common.imprint")}
          </P>
        </Link>
        <Link
          to="Info"
          params={{
            mode: "terms",
          }}
        >
          <P font="label" px={10} color="primary">
            {t("common.terms")}
          </P>
        </Link>
        <Link
          to="Info"
          params={{
            mode: "privacy",
          }}
        >
          <P font="label" px={10} color="primary">
            {t("common.privacy")}
          </P>
        </Link>
      </Flex>
    </Page>
  );
}
