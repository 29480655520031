import React from "react";
import { Flex, H5, P, Button, Icon, useTheme } from "unikit";

import { useAppState, useNavigation } from "../AppContext";

import Lottie from "./Lottie";

const NoContent = ({
  title,
  width,
  height = 275,
  desc,
  action,
  actionTitle,
  animationData = require("../assets/animations/piggy_crying.json"),
  horizontal = false,
  pro = false,
  noAni = false,
  ...rest
}) => {
  const theme = useTheme();
  const { navigate } = useNavigation();
  const { user } = useAppState();

  return (
    <Flex
      w={horizontal ? theme.width : "100%"}
      minHeight={height}
      flexCenter
      {...rest}
    >
      {animationData && noAni !== true ? (
        <Lottie
          width={200}
          height={200}
          mt={-70}
          animationData={animationData}
        />
      ) : null}
      <H5 mt={7} mb={4}>
        {title}
      </H5>
      {desc && (
        <P maxWidth={200} textAlign="center" opacity={0.5}>
          {desc}
        </P>
      )}
      {action ? (
        <Button mt={15} onPress={() => action()} rounded light>
          {actionTitle || <Icon name="arrowRight" size={24} />}
        </Button>
      ) : null}
    </Flex>
  );
};

export default NoContent;
